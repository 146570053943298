<template>
  <div class="main dark_mode">
    <div class="dark">
        <div class="top_area fix_top">
            <div class="container">
                <div class="member_tab">
                    <ul>
                        <li class="active">會員卡</li>
                        <li><router-link to="/gift-coupon">禮物券</router-link></li>
                        <li><router-link to="/brand">品牌牆</router-link></li>
                    </ul>
                </div>
                <button class="btn_search"></button>
            </div>
        </div> <!--end of top_bar-->
        <div class="content">
            <div class="container">
                <div class="member_card">
                    <ul>
                        <li><router-link to="/member-card-info">
                            <div class="img"><img src="@/assets/images/idx-slider-6.jpg"></div>
                            <div class="logo"><img src="@/assets/images/member-logo-1.png"><br>餡老滿</div>
                            <div class="cover">
                                <span class="info">宣傳文字內容</span>
                            </div></router-link>
                        </li>
                        <li>
                            <div class="img"><img src="@/assets/images/idx-slider-7.jpg"></div>
                            <div class="logo"><img src="@/assets/images/member-logo-2.png"><br>築間餐飲集團</div>
                            <div class="cover">
                                <span class="info">宣傳文字內容</span>
                            </div>
                        </li>
                        <li>
                            <div class="img"><img src="@/assets/images/idx-slider-8.jpg"></div>
                            <div class="logo"><img src="@/assets/images/member-logo-3.png"><br>孫東寶 台式牛排教父</div>
                            <div class="cover">
                                <span class="info">宣傳文字內容</span>
                            </div>
                        </li>
                        <li>
                            <div class="img"><img src="@/assets/images/idx-slider-9.jpg"></div>
                            <div class="logo"><img src="@/assets/images/member-logo-1.png"><br>餡老滿</div>
                            <div class="cover">
                                <span class="info">宣傳文字內容</span>
                            </div>
                        </li>
                        <li>
                            <div class="img"><img src="@/assets/images/idx-slider-10.jpg"></div>
                            <div class="logo"><img src="@/assets/images/member-logo-2.png"><br>築間餐飲集團</div>
                            <div class="cover">
                                <span class="info">宣傳文字內容</span>
                            </div>
                        </li>
                        <li>
                            <div class="img"><img src="@/assets/images/idx-slider-11.jpg"></div>
                            <div class="logo"><img src="@/assets/images/member-logo-3.png"><br>孫東寶 台式牛排教父</div>
                            <div class="cover">
                                <span class="info">宣傳文字內容</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div> <!--end of content-->
    </div>
    <FooterMenu :darkMode="isDarkMode" />
  </div>
</template>

<script>

import FooterMenu from '@/components/Footer.vue'
export default {
  name: 'memberCard',
  data() {
    return {
        isDarkMode: true
    };
  },
  components: {
    FooterMenu,
  },
  methods: {
    
  }
}
</script>