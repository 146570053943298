<template>
  <div class="main">
    <div class="my">
        <div class="top_bar">
            <div class="container">
                <div class="user_img"><img src="@/assets/images/footer-icon-5.svg"></div>
                <div class="user_info">
                    <span class="name">王小明</span>
                    <span class="phone">0912-345-678</span>
                </div>
                <div class="btn_notice">
                    <button @click="gotoNotice">
                        <img src="@/assets/images/icon-bell.svg"><br>
                        通知中心
                    </button>
                </div>
            </div>
        </div> <!--end of top_bar-->
        <div class="my_list">
            <div class="container">
                <ul>
                    <li>
                        <router-link to="/my-favorite">
                        <div class="img"><img src="@/assets/images/icon-liked-black.svg"></div>
                        <span>口袋名單</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/my-present">
                        <div class="img"><img src="@/assets/images/icon-gift.svg"></div>
                        <div class="words">
                            <span>禮物盒</span>
                            <span class="sub">0 個未使用項目</span>
                        </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/mission">
                        <div class="img"><img src="@/assets/images/icon-mission.svg"></div>
                        <span>任務專區</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/invite">
                        <div class="img"><img src="@/assets/images/icon-invite.svg"></div>
                        <span>邀請好友賺 Ocoin</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/exchange-number">
                        <div class="img"><img src="@/assets/images/icon-ticket.svg"></div>
                        <span>登錄兌換序號</span>
                        </router-link>
                    </li>
                </ul>
            
            <!--<h1>回饋獎勵</h1>
            <ul>
                <li>
                    <router-link to="/setting-feedback">
                    <div class="img"><img src="@/assets/images/icon-money.svg"></div>
                    <div class="words">
                        <span>設定回饋獎勵</span>
                        <span class="sub">已設定為 Ocoin 回饋獎勵</span>
                    </div>
                    </router-link>
                </li>
                <li>
                    <div class="img"><img src="@/assets/images/icon-clock-black.svg"></div>
                    <span>回饋獎勵明細</span>
                </li>
            </ul>-->
                <h1>其他</h1>
                <ul>
                    <li>
                        <router-link to="/edit-profile">
                        <div class="img"><img src="@/assets/images/icon-edit.svg"></div>
                        <span>修改個人資料</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/e-invoice">
                        <div class="img"><img src="@/assets/images/icon-barcode.svg"></div>
                        <span>電子發票手機條碼</span>
                        </router-link>
                    </li>
                    <li ref="listItem">
                        <div class="img"><img src="@/assets/images/icon-home.svg"></div>
                        <div class="words">
                            <span>設定APP開啟初始頁</span>
                            <span class="sub">{{ start }}</span>
                        </div>
                    </li>
                    <li>
                        <router-link to="/push">
                        <div class="img"><img src="@/assets/images/icon-bell-black.svg"></div>
                        <span>推播通知設定</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/auth">
                        <div class="img"><img src="@/assets/images/icon-filter.svg"></div>
                        <span>授權管理</span>
                        </router-link>
                    </li>
                    <li>
                        <div class="img"><img src="@/assets/images/icon-alert.svg"></div>
                        <span>回報管理</span>
                    </li>
                </ul>
            </div>
        </div> <!--end of my_list-->
        <el-drawer
            title="設定APP開啟初始頁"
            :visible.sync="dialog"
            direction="btt"
            custom-class="demo-drawer"
            ref="drawer"
            size="40%"
            >
            <div class="demo-drawer__content">
                <ul>
                    <li><button class="btn_normal" @click="updateStart('探索')">探索</button></li>
                    <li><button class="btn_normal" @click="updateStart('DAOCoin')">DAOCoin</button></li>
                    <li><button class="btn_normal" @click="updateStart('會員卡')">會員卡</button></li>
                    <li><button class="btn_cancel" @click="dialog = false">取消</button></li>
                </ul>
            </div>
        </el-drawer> 
    </div>
    
    <FooterMenu />
  </div>
</template>

<script>

import FooterMenu from '@/components/Footer.vue'
export default {
  name: 'myPage',
  data() {
    return {
        dialog: false,
        start: '探索'
    };
  },
  components: {
    FooterMenu,
  },
  mounted() {
    this.$refs.listItem.addEventListener('click', this.handleClick);
  },
  methods: {
    handleClick() {
      this.dialog = true;
    },
    updateStart(value) {
      this.start = value;
      this.dialog = false;
    },
    gotoNotice() {
        return this.$router.push('notice')
    }
  }
}
</script>