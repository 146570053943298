<template>
    <div class="online white_drawer">
        <div class="close_bar">
            <div class="container">
                <button @click="goback" class="btn_close"></button>
            </div>
        </div>
        <div class="top_bar text-left">
            <div class="container">
                <button class="btn_language">繁體中文</button>
                <button class="btn_user" @click="dialog_menu = true"><img src="@/assets/images/idx-slider-3.jpg"></button>
            </div>
        </div>
        <div class="top_area">
            <div class="shop_img" :style="{ 'background-image': 'url(' + require('@/assets/images/idx-slider-13.jpg') + ')' }"></div>
            <h1>鮮芋仙</h1>
            <p class="sub_title">Dao 線上點</p>
        </div>
        <div class="tabs">
            <div class="container">
                <div class="inputs top">
                    <ul>
                        <li>
                            <div class="img"><img src="@/assets/images/icon-store-black.svg"></div>
                            <div class="text">
                                <label>篩選</label>
                                <el-select v-model="filter" placeholder="請選擇">
                                    <el-option
                                    v-for="item in options_filter"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </li>
                    </ul>
                </div>
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane name="first">
                        <span slot="label"><i class="el-icon-map-location"></i><br>區域搜尋</span>
                        <div class="inputs">
                            <ul>
                                <li>
                                    <div class="img"><img src="@/assets/images/icon-navigation.svg"></div>
                                    <div class="text">
                                        <label>縣市</label>
                                        <el-select v-model="city" placeholder="請選擇">
                                            <el-option
                                            v-for="item in options_city"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="store_list">
                            <ul>
                                <li>
                                    <router-link to='/online-booking'>
                                    <div class="circle"><img src="@/assets/images/icon-store-black.svg"></div>
                                    <div class="text">
                                        <h3>鮮芋仙新店裕隆城店</h3>
                                        <span class="address">新北市新店區中興路三段70號B1</span>
                                    </div>
                                    </router-link>
                                </li>
                                <li>
                                    <div class="circle"><img src="@/assets/images/icon-store-black.svg"></div>
                                    <div class="text">
                                        <h3>永和比漾店</h3>
                                        <span class="address">新北市永和區中山路一段238號B2</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="circle"><img src="@/assets/images/icon-store-black.svg"></div>
                                    <div class="text">
                                        <h3>台北三創店</h3>
                                        <span class="address">台北市中山區市民大道三段2號B2</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="circle"><img src="@/assets/images/icon-store-black.svg"></div>
                                    <div class="text">
                                        <h3>板橋新埔店</h3>
                                        <span class="address">新北市板橋區文化路一段360號B1-21</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="second">
                        <span slot="label"><i class="el-icon-aim"></i><br>離我最近</span>
                        <p class="memo">下列顯示您與該分店據點之距離，僅供參考。<br>若店家提供運送訂單服務，將以實際路線導航做計算距離判定服務範圍及運送費用。</p>
                        <div class="store_list">
                            <ul>
                                <li>
                                    <router-link to='/online-booking'>
                                    <div class="circle pin"><img src="@/assets/images/icon-pin.svg"><br><span>0m</span></div>
                                    <div class="text">
                                        <h3>鮮芋仙新店裕隆城店</h3>
                                        <span class="address">新北市新店區中興路三段70號B1</span>
                                    </div>
                                    </router-link>
                                </li>
                                <li>
                                    <div class="circle pin"><img src="@/assets/images/icon-pin.svg"><br><span>0m</span></div>
                                    <div class="text">
                                        <h3>永和比漾店</h3>
                                        <span class="address">新北市永和區中山路一段238號B2</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="circle pin"><img src="@/assets/images/icon-pin.svg"><br><span>0m</span></div>
                                    <div class="text">
                                        <h3>台北三創店</h3>
                                        <span class="address">台北市中山區市民大道三段2號B2</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="circle pin"><img src="@/assets/images/icon-pin.svg"><br><span>0m</span></div>
                                    <div class="text">
                                        <h3>板橋新埔店</h3>
                                        <span class="address">新北市板橋區文化路一段360號B1-21</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>

        <!--選單-->
        <OnlineMenu :dialog_menu.sync="dialog_menu" @go-faq="goFAQ"/>

    </div>
</template>

<script>
import OnlineMenu from '@/components/OnlineMenu.vue'
export default {
    data() {
      return {
        dialog_menu: false,
        dialog_group: false,
        dialog_group2: false,
        openInfo: false,
        dialog: false,
        dialog_items: false,
        activeName: 'first',
        options_filter: [{
          value: '1',
          label: '全部店家'
        }, {
          value: '2',
          label: '提供配送店家'
        }, {
          value: '3',
          label: '提供自取店家'
        }],
        filter: '1',
        options_city: [{
          value: '1',
          label: '全台縣市'
        }, {
          value: '2',
          label: '台北市'
        }, {
          value: '3',
          label: '新北市'
        }],
        city: '1',
      }
    },
    components: {
        OnlineMenu
    },
    computed: {
        isLineView() {
            return this.view === 'line';
        },
        isBlockView() {
            return this.view === 'block';
        },
        listClass() {
            return {
                line: this.isLineView,
                block: this.isBlockView,
            };
        },
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        goback(){
            return this.$router.go(-1);
        },
        setView(viewType) {
            this.view = viewType;
        },
        openGroup(){
            this.dialog_group = false;
            this.dialog_group2 = true;
        },
        goFAQ() {
            if (this.$route.path !== '/faq') {
                this.$router.push('/faq');
            }
        }
    }
}
</script>

<style>

</style>