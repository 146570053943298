var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"exhange page"},[_c('div',{staticClass:"top_bar large",class:{ scrolled: _vm.isScrolled },style:({ 'background-image': 'url(' + require('@/assets/images/transfer-1.png') + ')' })},[_c('div',{staticClass:"container"},[_c('button',{staticClass:"btn_back",on:{"click":function($event){return _vm.goback()}}}),_c('div',{staticClass:"logo",style:({ height: _vm.logoHeight })},[_c('img',{attrs:{"src":require("@/assets/images/transfer-1.png")}})])])]),_vm._v(" "),_c('div',{staticClass:"bottom_bar large",class:{ scrolled: _vm.isScrolled }},[_c('div',{staticClass:"container"},[_vm._m(0),_c('ul',{staticClass:"coupon"},[_c('li',[_c('router-link',{attrs:{"to":"platform-content"}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/transfer-1-coupon.png")}})])]),_vm._m(1)],1),_c('li',[_c('router-link',{attrs:{"to":"platform-content"}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/transfer-1-coupon.png")}})])]),_vm._m(2)],1),_c('li',[_c('router-link',{attrs:{"to":"platform-content"}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/transfer-1-coupon.png")}})])]),_vm._m(3)],1),_c('li',[_c('router-link',{attrs:{"to":"platform-content"}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/transfer-1-coupon.png")}})])]),_vm._m(4)],1),_c('li',[_c('router-link',{attrs:{"to":"platform-content"}},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/transfer-1-coupon.png")}})])]),_vm._m(5)],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"store_name"},[_vm._v(" 全家便利商店"),_c('br'),_c('span',{staticClass:"word_s"},[_vm._v("Fa點")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_vm._v("Fa 點 2,000點 "),_c('div',{staticClass:"point"},[_vm._v("10 "),_c('img',{attrs:{"src":require("@/assets/images/coin-doucoing.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_vm._v("Fa 點 2,000點 "),_c('div',{staticClass:"point"},[_vm._v("10 "),_c('img',{attrs:{"src":require("@/assets/images/coin-doucoing.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_vm._v("Fa 點 2,000點 "),_c('div',{staticClass:"point"},[_vm._v("10 "),_c('img',{attrs:{"src":require("@/assets/images/coin-doucoing.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_vm._v("Fa 點 2,000點 "),_c('div',{staticClass:"point"},[_vm._v("10 "),_c('img',{attrs:{"src":require("@/assets/images/coin-doucoing.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_vm._v("Fa 點 2,000點 "),_c('div',{staticClass:"point"},[_vm._v("10 "),_c('img',{attrs:{"src":require("@/assets/images/coin-doucoing.svg")}})])])
}]

export { render, staticRenderFns }