<template>
    <div class="search">
        <div class="top_area result">
            <div class="container">
                <router-link to="/"><button class="btn_back"></button></router-link>
                <!-- <input class="input_search" placeholder="請輸入關鍵字"> -->
                <el-input class="input_search" placeholder="請輸入關鍵字" v-model="input" clearable></el-input>
                <button class="btn_map"></button>
            </div>
            <div class="tab_area">
                <div class="container">
                    <button class="filter" @click="dialog = true">篩選{{ filter_items }}</button>
                    <ul class="tab">
                        <li class="current">距離排序</li>
                        <li>評分優先</li>
                        <li>熱門排序</li>
                        <li>新進店家</li>
                    </ul>
                </div>
            </div> <!--end of tab_area-->
        </div> <!--end of top_input-->
        <div class="content result">
            <div class="container">
                <ul class="shop_list">
                    <li>
                        <router-link to="/coupon">
                        <button class="btn_like active"></button>
                        <div class="img"><img src="@/assets/images/idx-slider-5.jpg"></div>
                        <div class="shop_info">
                            <div class="tag">#手作甜點 #微奢甜點季 #日式洋果子</div>
                            <div class="name">海海洋菓</div>
                            <div class="info">
                                <span class="star">4.3 ★</span>
                                <span class="location">4.5km</span>
                                <span class="pointback">到店1.5% 回饋</span>
                            </div>
                        </div>
                        </router-link>
                </li>
                <li>
                        <router-link to="/coupon">
                        <button class="btn_like"></button>
                        <div class="img"><img src="@/assets/images/idx-slider-6.jpg"></div>
                        <div class="shop_info">
                            <div class="tag">#手作甜點 #微奢甜點季 #日式洋果子</div>
                            <div class="name">海海洋菓</div>
                            <div class="info">
                                <span class="star">4.3 ★</span>
                                <span class="location">4.5km</span>
                                <span class="pointback">到店1.5% 回饋</span>
                            </div>
                        </div>
                        </router-link>
                </li>
                <li>
                        <button class="btn_like"></button>
                        <div class="img"><img src="@/assets/images/idx-slider-7.jpg"></div>
                        <div class="shop_info">
                            <div class="tag">#手作甜點 #微奢甜點季 #日式洋果子</div>
                            <div class="name">海海洋菓</div>
                            <div class="info">
                                <span class="star">4.3 ★</span>
                                <span class="location">4.5km</span>
                                <span class="pointback">到店1.5% 回饋</span>
                            </div>
                        </div>
                </li>
                <li>
                        <button class="btn_like"></button>
                        <div class="img"><img src="@/assets/images/idx-slider-8.jpg"></div>
                        <div class="shop_info">
                        <div class="tag">#手作甜點 #微奢甜點季 #日式洋果子</div>
                        <div class="name">海海洋菓</div>
                        <div class="info">
                            <span class="star">4.3 ★</span>
                            <span class="location">4.5km</span>
                            <span class="pointback">到店1.5% 回饋</span>
                        </div>
                        </div>
                </li>
                </ul>
                <el-drawer
                    title="篩選"
                    :visible.sync="dialog"
                    direction="btt"
                    custom-class="demo-drawer"
                    ref="drawer"
                    size="70%"
                    >
                    <div class="demo-drawer__content">
                        <span class="title"><img src="@/assets/images/icon-click.svg">線上外帶外送</span>
                        <el-checkbox-group v-model="checkboxGroup1" size="medium">
                            <el-checkbox-button v-for="way in ways" :label="way" :key="way">{{way}}</el-checkbox-button>
                        </el-checkbox-group>
                        <span class="title"><img src="@/assets/images/icon-map-gray.svg">距離</span>
                        <el-checkbox-group v-model="checkboxGroup2" size="medium">
                            <el-checkbox-button v-for="distance in km" :label="distance" :key="distance">{{distance}}</el-checkbox-button>
                        </el-checkbox-group>
                        <span class="title"><img src="@/assets/images/icon-click.svg">快選</span>
                        <el-checkbox-group v-model="checkboxGroup3" size="medium">
                            <el-checkbox-button v-for="quick in select" :label="quick" :key="quick">{{quick}}</el-checkbox-button>
                        </el-checkbox-group>
                        <span class="title"><img src="@/assets/images/icon-type.svg">類型</span>
                        <el-checkbox-group v-model="checkboxGroup4" size="medium">
                            <el-checkbox-button v-for="type in types" :label="type" :key="type">{{type}}</el-checkbox-button>
                        </el-checkbox-group>
                        <span class="title"><img src="@/assets/images/icon-mrt.svg">捷運商圈</span>
                        <el-checkbox-group v-model="checkboxGroup5" size="medium">
                            <el-checkbox-button v-for="mrt in mrts" :label="mrt" :key="mrt">{{mrt}}</el-checkbox-button>
                        </el-checkbox-group>
                        <span class="title"><img src="@/assets/images/icon-dao.svg">DAOCoin</span>
                        <el-checkbox-group v-model="checkboxGroup6" size="medium">
                            <el-checkbox-button v-for="coin in coins" :label="coin" :key="coin">{{coin}}</el-checkbox-button>
                        </el-checkbox-group>
                        <div class="demo-drawer__footer">
                            <el-button class="btn_clear" @click="cancelForm">清空</el-button>
                            <el-button @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '篩選中 ...' : '套用' }}</el-button>
                        </div>
                    </div>
                </el-drawer> 
            </div>
        </div> <!--end of content-->
    </div>
</template>

<script>
// @ is an alias to /src
const wayOptions = ['外帶', '外送', '即時', '預約'];
const distanceOptions = ['<2km', '<5km', '<8km', '<10km'];
const quickOptions = ['我的口袋名單', '線上點 Ocard Rewards', '到店 Ocard Rewards', '營業中', '會員招募中', '嚐鮮優惠券'];
const typeOptions = ['餐飲', '住宿', '娛樂', '生活', '藥妝', '零售', '美容'];
const mrtOptions = ['台北捷運'];
const coinOptions = ['DAO賺取', 'DAO折抵', 'DAO兌換'];
export default {
    name: 'SearchResult',
    data() {
        return {
            input: '#嚐鮮享優惠',
            filter_items: '1',
            dialog: false,
            loading: false,
            checkboxGroup1: [],
            ways: wayOptions,
            checkboxGroup2: [],
            km: distanceOptions,
            checkboxGroup3: ['嚐鮮優惠券'],
            select: quickOptions,
            checkboxGroup4: [],
            types: typeOptions,
            checkboxGroup5: [],
            mrts: mrtOptions,
            checkboxGroup6: [],
            coins: coinOptions,
            timer: null,
        };
    },
    components: {
        
    },
    methods: {
        cancelForm() {
            this.loading = false;
            this.dialog = false;
            clearTimeout(this.timer);
        }
    }

}
</script>