import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDohKM1AJRw6QvUuTBNgJWr-s942YDFlqw', // 替换成你的Google Maps API key
    libraries: 'places', // 如果你需要使用places库
  },
});

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.use(MintUI)

import Swiper from 'r-swiper2'
Vue.use(Swiper)

import '@/assets/sass/style.sass';
import '@/assets/sass/rwd.sass';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
