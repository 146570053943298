<template>
  <div class="main dark_mode">
    <div class="dark">
        <div class="top_area fix_top">
            <div class="member_tab">
                <ul>
                    <li><router-link to="/member-card">會員卡</router-link></li>
                    <li><router-link to="/gift-coupon">禮物券</router-link></li>
                    <li class="active">品牌牆</li>
                </ul>
            </div>
        </div> <!--end of top_bar-->
        <div class="content">
          <div class="container">
            <div class="brand">
                <h3>尚未接受邀請</h3>
                <ul class="brand_list">
                    <li><button @click="dialog = true"><img src="@/assets/images/brand-1.png"><br>小蒙牛餐飲集團</button></li>
                    <li><img src="@/assets/images/brand-2.png"><br>頂呱呱集團 KWAKWA CLUB</li>
                    <li><img src="@/assets/images/brand-3.png"><br>起家雞韓式炸雞 Cheogajip</li>
                    <li><img src="@/assets/images/brand-4.png"><br>果果迪</li>
                </ul>
                <h3>其他品牌</h3>
                <ul class="brand_list">
                    <li><img src="@/assets/images/brand-5.png"><br>Ocard</li>
                </ul>
                <h3>DAOcoin 合作品牌</h3>
                <ul class="brand_list">
                    <li><img src="@/assets/images/brand-6.png"><br>聖瑪莉</li>
                    <li><img src="@/assets/images/brand-7.png"><br>普觀福報銀行</li>
                    <li><img src="@/assets/images/brand-8.png"><br>台灣吉野家</li>
                    <li><img src="@/assets/images/brand-9.png"><br>神來平價美食天堂</li>
                    <li><img src="@/assets/images/brand-10.png"><br>滙聚智能販賣機</li>
                    <li><img src="@/assets/images/brand-11.png"><br>放感情</li>
                </ul>
            </div>
          </div>
        </div> <!--end of content-->

        <div class="white_drawer">
          <el-drawer
              title="收到新的會員邀請"
              :visible.sync="dialog"
              direction="btt"
              custom-class="demo-drawer"
              ref="drawer"
              size="55%"
              >
              <div class="demo-drawer__content">
                <div class="shop_brand">
                  <div class="img"><img src="@/assets/images/idx-slider-6.jpg"></div>
                  <div class="logo"><img src="@/assets/images/member-logo-1.png"><br>小蒙牛餐飲集團</div>
                  <div class="cover">
                      <span class="info">小蒙牛餐飲集團集點會員</span>
                  </div>
                </div> <!--end of shop_brand-->
                <div class="hint">點按接受，即表示您接受同意該店之隱私權條款</div>
                <div class="btns">
                  <button class="btn_cancel" @click="dialog = false">先不要</button>
                  <button class="btn_submit" @click="dialog = false">接受</button>
                </div>
              </div>
          </el-drawer> 
        </div>
    </div>
    <FooterMenu :darkMode="isDarkMode" />
  </div>
</template>

<script>

import FooterMenu from '@/components/Footer.vue'
export default {
  name: 'brand',
  data() {
    return {
        isDarkMode: true,
        dialog: false
    };
  },
  components: {
    FooterMenu,
  },
  methods: {
    
  }
}
</script>