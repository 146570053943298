import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Search from '../views/Search.vue'
import SearchResult from '../views/SearchResult.vue'
import SearchCoupon from '../views/SearchCoupon.vue'
import Coupon from '../views/Coupon.vue'
import Store from '../views/Store.vue'
import OnlineBooking from '../views/OnlineBooking.vue'
import OnlineStore from '../views/OnlineStore.vue'
import Order from '../views/Order.vue'
import FAQ from '../views/FAQ.vue'
import SearchMap from '../views/SearchMap.vue'
import Mission from '../views/Mission.vue'
import MissionContent from '../views/MissionContent.vue'
//DaoCoin
import DaoCoin from '../views/DaoCoin.vue'
import FeedbackDetail from '../views/FeedbackDetail.vue'
import ExchangeToDao from '../views/ExchangeToDao.vue'
import ExchangeStore from '../views/ExchangeStore.vue'
import ExchangePlatform from '../views/ExchangePlatform.vue'
import Platform from '../views/Platform.vue'
import ExchangeContent from '../views/ExchangeContent.vue'
import PlatformContent from '../views/PlatformContent.vue'
import Introduce from '../views/Introduce.vue'
//掃描
import Scan from '../views/Scan.vue'
//會員卡
import MemberCard from '../views/MemberCard.vue'
import MemberCardInfo from '../views/MemberCardInfo.vue'
import PointExhangeInfo from '../views/PointExhangeInfo.vue'
import CouponInfo from '../views/CouponInfo.vue'
import Article from '../views/Article.vue'
import GiftCoupon from '../views/GiftCoupon.vue'
import Brand from '../views/Brand.vue'
//我的
import MyPage from '../views/MyPage.vue'
import MyFavorite from '../views/MyFavorite.vue'
import MyPresent from '../views/MyPresent.vue'
import ExchangeNumber from '../views/ExchangeNumber.vue'
import SettingFeedback from '../views/SettingFeedback.vue'
import EditProfile from '../views/EditProfile.vue'
import DeleteAccount from '../views/DeleteAccount.vue'
import EInvoice from '../views/EInvoice.vue'
import Invite from '../views/Invite.vue'
import Push from '../views/Push.vue'
import Auth from '../views/Auth.vue'
import Notice from '../views/Notice.vue'
Vue.use(VueRouter)

const routes = [
  {
    //探索首頁
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    //搜尋
    path: '/search',
    name: 'search',
    component: Search
  },
  {
    //搜尋結果-餐廳
    path: '/search-result',
    name: 'searchResult',
    component: SearchResult
  },
  {
    //搜尋結果-優惠券
    path: '/search-coupon',
    name: 'searchCoupon',
    component: SearchCoupon
  },
  {
    //搜尋結果-地圖模式（未完成）
    path: '/search-map',
    name: 'searchMap',
    component: SearchMap
  },
  {
    //任務專區
    path: '/mission',
    name: 'mission',
    component: Mission
  },
  {
    //任務內容（未完成）
    path: '/mission-content',
    name: 'missionContent',
    component: MissionContent
  },
  {
    //嚐鮮券
    path: '/coupon',
    name: 'coupon',
    component: Coupon
  },
  {
    //店家資訊
    path: '/store',
    name: 'store',
    component: Store
  },
  {
    //店家資訊-線上點-外送上門/外帶自取
    path: '/online-booking',
    name: 'onlineBooking',
    component: OnlineBooking
  },
  {
    //店家資訊-線上點-店家資訊-區域搜尋/離我最近
    path: '/online-store',
    name: 'onlineStore',
    component: OnlineStore
  },
  {
    //我的訂單
    path: '/order',
    name: 'order',
    component: Order
  },
  {
    //常見問題
    path: '/faq',
    name: 'faq',
    component: FAQ
  },
  {
    //DaoCoin首頁
    path: '/dao-coin',
    name: 'daoCoin',
    component: DaoCoin
  },
  {
    //回饋獎勵明細
    path: '/feedback-detail',
    name: 'feedbackDetail',
    component: FeedbackDetail
  },
  {
    //轉成 DaoCoin
    path: '/exchange-to-dao',
    name: 'exchangeToDao',
    component: ExchangeToDao
  },
  {
    //線上兌換店家頁
    path: '/exchange-store',
    name: 'exchangeStore',
    component: ExchangeStore
  },
  {
    //平台兌換店家頁
    path: '/exchange-platform',
    name: 'exchangePlatform',
    component: ExchangePlatform
  },
  {
    // 兌換平台 可兌換項目
    path: '/platform',
    name: 'platform',
    component: Platform
  },
  {
    // 兌換平台 兌換內容
    path: '/platform-content',
    name: 'platformContent',
    component: PlatformContent
  },
  {
    // 了解 Rewards
    path: '/introduce',
    name: 'introduce',
    component: Introduce
  },
  {
    //線上兌換-兌換內容
    path: '/exchange-content',
    name: 'exchangeContent',
    component: ExchangeContent
  },
  {
    //掃描
    path: '/scan',
    name: 'scan',
    component: Scan
  },
  {
    //會員卡
    path: '/member-card',
    name: 'memberCard',
    component: MemberCard
  },
  {
    //會員卡資訊
    path: '/member-card-info',
    name: 'memberCardInfo',
    component: MemberCardInfo
  },
  {
    //點數兌換資訊
    path: '/point-exchange-info',
    name: 'pointExhangeInfo',
    component: PointExhangeInfo
  },
  {
    //禮券資訊
    path: '/coupon-info',
    name: 'couponInfo',
    component: CouponInfo
  },
  {
    //品牌動態-文章
    path: '/article',
    name: 'article',
    component: Article
  },
  {
    //禮物券
    path: '/gift-coupon',
    name: 'giftCoupon',
    component: GiftCoupon
  },
  {
    //品牌牆
    path: '/brand',
    name: 'brand',
    component: Brand
  },
  {
    //我的
    path: '/my-page',
    name: 'myPage',
    component: MyPage
  },
  {
    //口袋名單
    path: '/my-favorite',
    name: 'myFavorite',
    component: MyFavorite
  },
  {
    //登錄兌換序號
    path: '/exchange-number',
    name: 'exchangeNumber',
    component: ExchangeNumber
  },
  {
    //設定回饋獎勵
    path: '/setting-feedback',
    name: 'settingFeedback',
    component: SettingFeedback
  },
  {
    //修改個人資料
    path: '/edit-profile',
    name: 'editProfile',
    component: EditProfile
  },
  {
    //請求刪除會員
    path: '/delete-account',
    name: 'deleteAccount',
    component: DeleteAccount
  },
  {
    //禮物盒
    path: '/my-present',
    name: 'myPresent',
    component: MyPresent
  },
  {
    //雲端發票手機號碼
    path: '/e-invoice',
    name: 'eInvoice',
    component: EInvoice
  },
  {
    //邀請好友賺DAOcoin
    path: '/invite',
    name: 'Invite',
    component: Invite
  },
  {
    //推播通知設定
    path: '/push',
    name: 'push',
    component: Push
  },
  {
    //授權管理
    path: '/auth',
    name: 'auth',
    component: Auth
  },
  {
    //通知中心
    path: '/notice',
    name: 'notice',
    component: Notice
  }
]

const router = new VueRouter({
  routes
})

export default router
