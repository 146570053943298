<template>
  <div class="main">
    <div class="my_page">
        <div class="top_bar">
          <div class="container">
            <div class="title">
              <button @click="goback()" class="btn_close"></button>
              禮物盒</div>
          </div>
        </div> <!--end of top_bar-->
        <div class="content">
            <div class="tabs">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane label="未使用" name="first">
                        <div class="empty"><img src="@/assets/images/icon-empty.svg"><br>尚無未使用的禮物</div>
                    </el-tab-pane>
                    <el-tab-pane label="已核銷" name="second">
                        <div class="empty"><img src="@/assets/images/icon-empty.svg"><br>尚無已核銷的禮物</div>
                    </el-tab-pane> 
                    <el-tab-pane label="已過期" name="third">
                        <div class="empty"><img src="@/assets/images/icon-empty.svg"><br>尚無已過期的禮物</div>
                    </el-tab-pane>
                </el-tabs>
            </div> <!--end of tabs-->
        </div> <!--end of content-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'myPresent',
  data() {
    return {
        activeName: 'first',
    };
  },
  components: {
    
  },
  methods: {
    goback(){
        return this.$router.go(-1);
    }
  }
}
</script>