<template>
    <div class="store shop_page white_drawer">
        <div class="top_area" :class="{ scrolled: isScrolled }" :style="{ 'background-image': 'url(' + require('@/assets/images/idx-slider-10.jpg') + ')', height: logoHeight }">
            <div class="container">
                <button class="btn_like" :class="{ active: isLike }" @click=" isLike =! isLike "></button>
                <button class="btn_close" @click="goback"></button>
                <h1>海海洋菓 OPEN WATER</h1>
            </div>
        </div> <!--end of top_area-->
        <div class="bottom_area">
            <div class="container">
                <div class="data">
                    <ul>
                        <li>
                            4km<br><span class="word_s">距離</span>
                        </li>
                        <li>
                            4.3<img src="@/assets/images/icon-star.svg"><br><span class="word_s">平均評分</span>
                        </li>
                        <li>
                            <router-link to="/online-booking">
                                <img class="to-go" src="@/assets/images/icon-togo.svg"><br><span class="word_s">線上點</span>
                            </router-link>
                        </li>
                        <li>
                            3%<br><span class="word_s">到店消費回饋</span>
                        </li>
                    </ul>
                </div> <!--end of data-->
                <div class="tag">
                    #手作甜點 #微奢甜點季 指定店家 ＃日式洋菓子 #季節限定千層 ＃民生社區美食 ＃為你推薦 ＃下午茶
                </div> <!--end of tag-->
                <div class="info_profile">
                    <p>海海洋菓 甜點工作室<br>
                        「海」＋「海」的西洋菓子<br>
                        成員--海主廚 xI+ 海打雜 xI<br><br>
                        結合日式嚴謹和法式浪漫的手工創作<br><br>
                        工作室營運會不定時更動菜單和限定商品<br>
                        歡迎 FB 訊息和直接來電 02-27531776
                    </p>
                </div>
                <div class="tabs">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane label="店家資訊" name="first">
                            <ul class="info">
                                <li>
                                    <div class="img"><img src="@/assets/images/icon-phone.svg"></div> 02-2345-5678
                                </li>
                                <li>
                                    <div class="img"><img src="@/assets/images/icon-map-2.svg"></div> 台北市內湖區瑞光路283號
                                </li>
                                <li>
                                    <el-collapse v-model="shop" accordion>
                                        <el-collapse-item title="今日營業時間 13:00~19:00" name="1">
                                            <div>
                                                <p class="time">
                                                    週一 10:30~20:00<br>
                                                    週二 10:30~20:00<br>
                                                    週三 10:30~20:00<br>
                                                    週四 10:30~20:00<br>
                                                    週五 10:30~20:00<br>
                                                    週六 10:30~20:00<br>
                                                    週日 10:30~20:00
                                                </p>
                                            </div>
                                        </el-collapse-item>
                                    </el-collapse>
                                </li>
                                <li><div class="img"><img src="@/assets/images/icon-dollar.svg"></div> 平均消費 $$<span class="pay">$$$</span></li>
                            </ul>

                            <div class="photos">
                                <h2>圖集</h2>
                                <div class="photos_list">
                                    <el-image v-for="(item, index) in photo_items" :key="index"
                                        :src="item.url"
                                        :preview-src-list="adjustedPreviewList(index)">
                                    </el-image>
                                </div>
                            </div>
                            <div class="blogs">
                                <h2>食記</h2>
                                <ul>
                                    <li>
                                        <div class="description">
                                            <p>OpenWater 海海洋菓，萍子推薦民生社區甜點，法式水果千層，台北下午茶，台北咖啡館..</p>
                                            <a class="links">links</a>
                                        </div>
                                        <div class="img"><img src="@/assets/images/idx-slider-1.jpg"></div>
                                    </li>
                                    <li>
                                        <div class="description">
                                            <p>OpenWater 海海洋菓，萍子推薦民生社區甜點，法式水果千層，台北下午茶，台北咖啡館..</p>
                                            <span class="links"></span>
                                        </div>
                                        <div class="img"><img src="@/assets/images/idx-slider-2.jpg"></div>
                                    </li>
                                    <li>
                                        <div class="description">
                                            <p>OpenWater 海海洋菓，萍子推薦民生社區甜點，法式水果千層，台北下午茶，台北咖啡館..</p>
                                            <a class="links">links</a>
                                        </div>
                                        <div class="img"><img src="@/assets/images/idx-slider-3.jpg"></div>
                                    </li>
                                </ul>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="回饋與兌換" name="second">
                            <div class="point_back">
                                <h2>回饋獎勵
                                    <a @click="dialog = true"><span class="notice">注意事項 <img src="@/assets/images/icon-attention.svg"></span></a>
                                </h2>
                                <button class="btn_point_back" @click="dialog = true">
                                    可獲得 <span class="highlight">3</span>% DaoCoin
                                </button>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div> <!--end of tabs-->
            </div>
        </div> <!--end of bottom_area-->
        <el-drawer
            title="回饋獎勵"
            :visible.sync="dialog"
            direction="btt"
            custom-class="store_drawer"
            ref="drawer"
            size="85%"
            >
            <div class="demo-drawer__content">
                <div class="top"><span class="highlight">3</span>%<br>可獲得 3% <img src="@/assets/images/coin-doucoing.svg"> DaoCoin 線上點消費回饋</div>
                <div class="bottom">
                    <p>1.從以下路徑使用「Ocard 線上點」服務進行商品訂購，可享有線上回饋獎勵</p>
                    <p>路徑一：Ocard APP【探索】>【線上外帶外送回饋專區】＞【店家點餐頁】</p>
                    <p>路徑二：Ocard APP【探索】>【線上外帶外送回饋專區】＞【查看全部】>【店家頁】>【線上點】</p>
                    <p>路徑三：Ocard APP【探索】>【搜尋店家】>【店家頁】>【線上點】</p>
                    <p>2.結帳時，確認已經登入 Ocard 會員，並且有出現「OcardRewards」區塊能選擇您想要的回饋獎勵類型</p>
                    <p>3.回饋將於訂單完成後才會自動存入帳戶</p>
                    <p>4.累積成功時將以推播通知，亦可至「我的」>「回饋獎勵明細」，查看您的回饋賺取紀錄</p>
                    <p>注意事項</p>
                    <p>1.回饋獎勵之數量遇小數點時，採無條件捨去方式計算。</p>
                    <p>2.若是從 Ocard APP【會員卡】>【選擇品牌】>【品牌資訊】>【線上點】此路徑使用「Ocard 線上點」服務進行訂購，將無法獲得回饋獎勵！</p>
                    <p>2.若是從 Ocard APP【會員卡】>【選擇品牌】>【品牌資訊】>【線上點】此路徑使用「Ocard 線上點」服務進行訂購，將無法獲得回饋獎勵！</p>
                    <p>3.未收到回饋，請先確認訂單是否完成，若是發現訂單尚未完成，請聯繫 Ocard 客服並提供訂單相關資訊，以便 Ocard 客服協助您聯繫店家以完成該筆訂單。（客服信箱：service@ocard.co)</p>
                </div>
            </div>
        </el-drawer>
    </div>
    
</template>

<script>
export default {
    name: 'Coupon',
    data() {
      return {
        isLike: false,
        isScrolled: false, // 是否向下滚动
        logoHeight: '240px', // 初始logo高度
        bottomPadding: '250px', // 初始padding-top
        activeName: 'first',
        shop: ['1'],
        dialog: false,
        photo_items: [
            {
                url: require('@/assets/images/idx-slider-1.jpg'),
            },
            {
                url: require('@/assets/images/idx-slider-2.jpg'),
            },
            {
                url: require('@/assets/images/idx-slider-3.jpg'),
            },
            {
                url: require('@/assets/images/idx-slider-4.jpg'),
            },
            {
                url: require('@/assets/images/idx-slider-5.jpg'),
            },
            {
                url: require('@/assets/images/idx-slider-6.jpg'),
            },
            {
                url: require('@/assets/images/idx-slider-7.jpg'),
            }
        ],
        
      };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll); // 监听页面滚动事件
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll); // 移除滚动事件监听器
    },
    methods: {
       handleScroll() {
            // 获取页面滚动距离
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            // 根据页面滚动距离动态改变样式
            this.isScrolled = scrollTop > 0;
            // 计算动态高度
            this.logoHeight = Math.max(240 - scrollTop, 60) + 'px'; // 最小高度为60px
            // 计算动态padding-top
            this.bottomPadding = Math.max(250 - scrollTop, 60) + 'px'; // 最小padding-top为60px
        },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      handleChange(val) {
        console.log(val);
      },
      goback(){
        return this.$router.go(-1);
      },
      adjustedPreviewList(clickedIndex) {
        const urls = this.photo_items.map(item => item.url);
        const clickedUrl = urls.splice(clickedIndex, 1)[0];
        urls.unshift(clickedUrl);
        return urls;
        },
    }
}
</script>

<style>

</style>