<template>
  <div class="main no-padding">
    <div class="dark">
        <div class="top_area">
            <div class="container">
                <div class="title">推播通知設定
                    <button @click="goback()" class="btn_close"></button>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <h3>接收來自以下品牌的推播通知</h3>
                <div class="switch">
                    <ul>
                        <li>
                            <div class="logo"><img src="@/assets/images/push-logo-1.png"></div>
                            <div class="name">橘焱胡同</div>
                            <el-switch
                                v-model="value1"
                                active-color="var(--mainYellow)"
                                inactive-color="var(--mainText2)">
                            </el-switch>
                        </li>
                        <li>
                            <div class="logo"><img src="@/assets/images/push-logo-2.png"></div>
                            <div class="name">小蒙牛餐飲集團</div>
                            <el-switch
                                v-model="value2"
                                active-color="var(--mainYellow)"
                                inactive-color="var(--mainText2)">
                            </el-switch>
                        </li>
                        <li>
                            <div class="logo"><img src="@/assets/images/push-logo-3.png"></div>
                            <div class="name">頂呱呱集團 KWA KWA CLUB</div>
                            <el-switch
                                v-model="value3"
                                active-color="var(--mainYellow)"
                                inactive-color="var(--mainText2)">
                            </el-switch>
                        </li>
                        <li>
                            <div class="logo"><img src="@/assets/images/push-logo-4.png"></div>
                            <div class="name">起家雞韓式炸雞 Cheogajip</div>
                            <el-switch
                                v-model="value4"
                                active-color="var(--mainYellow)"
                                inactive-color="var(--mainText2)">
                            </el-switch>
                        </li>
                        <li>
                            <div class="logo"><img src="@/assets/images/push-logo-5.png"></div>
                            <div class="name">餡老滿</div>
                            <el-switch
                                v-model="value5"
                                active-color="var(--mainYellow)"
                                inactive-color="var(--mainText2)">
                            </el-switch>
                        </li>
                        <li>
                            <div class="logo"><img src="@/assets/images/push-logo-6.png"></div>
                            <div class="name">DAOCoin</div>
                            <el-switch
                                v-model="value6"
                                active-color="var(--mainYellow)"
                                inactive-color="var(--mainText2)">
                            </el-switch>
                        </li>
                        <li>
                            <div class="logo"><img src="@/assets/images/push-logo-7.png"></div>
                            <div class="name">東京純豆腐</div>
                            <el-switch
                                v-model="value7"
                                active-color="var(--mainYellow)"
                                inactive-color="var(--mainText2)">
                            </el-switch>
                        </li>
                    </ul>
                </div>
            </div> <!-- end of container-->
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'invite',
    data() {
        return {
            value1: true,
            value2: true,
            value3: true,
            value4: true,
            value5: true,
            value6: true,
            value7: true
        };
    },
    components: {

    },
    methods: {
        submit(){
            return this.$router.go(-1);
        },
        goback(){
            return this.$router.go(-1);
        }
    }
}
</script>