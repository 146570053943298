<template>
  <div class="main no-padding">
    <div class="dark">
        <div class="top_area">
            <div class="container">
                <div class="title">請求刪除會員
                    <button @click="goback()" class="btn_close"></button>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="inputs">
                    <div class="input">
                        <label>DAOCoin會員</label>
                        <input class="name" type="text">
                    </div>
                    <input class="email" placeholder="電子信箱">
                </div>
                <div class="comment">
                    <h3>注意事項</h3>
                    <ol>
                        <li>刪除 DAOcoin 會員申請需要經過人工處理，預計處理時間約為7到14個工作天，客服人員將透過 Email 或手機號碼與您聯繫。</li>
                        <li>一旦 DAOcoin 會員資料被刪除，您將無法再使用此 DAOcoin App與其他平台的 DAOcoin 應用程式和網頁服務，也無法再登入或建立新的 DAOcoin 會員帳號。</li>
                        <li>您需同意放棄所有已持有、獲得的品牌電子會員權益，包括但不限於會員資格、電子集點點數、禮物券⋯等。此外，您日後也無法再參與 DAOcoin 或 DAOcoin APP 與其合作品牌提供的電子會員權益和相關優惠或活動。</li>
                        <li>您需同意放棄所有已經賺取的 DAOcoin Rewards 回饋獎勵，以及未兌換的禮物盒項目。</li>
                        <li>您需同意所有於 DAOcoin 系統內購買的線上點或電子票券訂單將無法查看或使用。</li>
                        <li>刪除會員帳號後的365天內，您無法使用相同的手機號碼重新登入或建立新的 DAOcoin 會員帳號。</li>
                    </ol>
                </div>
                <div class="btns">
                    <el-checkbox v-model="checked">我已閱讀並且同意上述事項</el-checkbox>
                    <button @click="submit()" class="btn_submit delete" :disabled="checked==false">送出申請</button>
                </div>
            </div>
        </div> <!--end of content-->
    </div>
  </div>
</template>
<script>
export default {
    name: 'deleteAccount',
    data() {
        return {
            checked: false
        };
    },
    components: {

    },
    methods: {
        submit(){
            return this.$router.go(-1);
        },
        goback(){
            return this.$router.go(-1);
        }
    }
}
</script>