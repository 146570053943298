<template>
    <div class="main">
        <div class="exhange page">
            <div class="top_bar" :style="{ 'background-image': 'url(' + require('@/assets/images/online-1.png') + ')' }">
                <button @click="goback()" class="btn_back"></button>
                兌換內容
            </div> <!--end of top_bar-->
            <div class="bottom_bar">
                <div class="exchange_img">
                    <img src="@/assets/images/exchange-coupon-1.png">
                </div> <!--end of exchange_img-->
                <div class="exchange_title">
                    台灣大車隊即享券30元
                    <div class="point">45 <img src="@/assets/images/coin-doucoing.svg"></div>
                </div> <!--end of exchange_title-->
                <div class="contnet">
                    <h1>內容</h1>
                    <p>台灣大車隊即享券 30元</p>
                    <h1>注意事項</h1>
                    <p>
                        ・有效期限：自發出日起算3個月<br>
                        ・使用本券須先至台灣大車隊 55688 APP 將序號歸戶至會員帳號（手機門號）內，並完成信用卡綁定後方可使用（每次搭乘限用一張）<br>
                        ・APP 歸戶流程：登入歸戶者帳號→選擇右下功能→我的→我的搭車金→輸入開通碼（本券序號）→領取。<br>
                        ・搭車流程：使用APP 叫車-付款方式選擇「信用卡」。<br>
                        ・如車資超過本券面額，超過之金額會直接使用綁訂的信用卡扣款。<br>
                        ・本券無法兌換現金或找零，請一次抵用完畢，不可重複使用。<br>
                        ・本券須於序號效期前完成歸戶，搭車金的有效期限為歸戶後的90天。<br>
                        ・本券序號具唯一性，一組序號僅供歸戶一次，歸戶後該序號視同已兌換，不接受取消或返還，亦不可轉換為現金。
                    </p>
                </div> <!--end of contnet-->
                <div class="btns fix_bottom">
                    <button @click="submit()" class="btn_submit">進行兌換</button>
                </div>
            </div> <!--end of bottom_bar-->
        </div> <!--end of exhange-->
    </div> <!--end of main-->
</template>
<script>
export default {
    name: 'exchangeContent',
    data() {
        return {
            
        };
    },
    components: {

    },
    mounted() {
        
    },
    methods: {
        goback(){
            return this.$router.go(-1);
        },
        submit(){
            return this.$router.go(-1);
        },
    },
}
</script>