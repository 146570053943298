<template>
  <div class="main no-padding">
    <div class="dark">
        <div class="top_area">
            <div class="title">設定回饋獎勵</div>
            <button @click="goback()" class="btn_close"></button>
        </div>
        <div class="content">
            <div class="sub">從下方選擇你想要的回饋獎勵，到 DAOCoin 特約店家消費時，<span class="highlight">結帳報手機號碼就能輕鬆賺取回饋</span>。</div>
            <div class="choice_feedback">
                <el-radio v-model="radio1" label="1" border>
                    <img src="@/assets/images/feedback-1.svg">
                </el-radio>
                <el-radio v-model="radio1" label="2" border>
                    <img src="@/assets/images/feedback-2.png"><br>
                    <span class="account">尚未連結帳戶</span>
                </el-radio>
                <el-radio v-model="radio1" label="3" border>
                    <img src="@/assets/images/feedback-3.png"><br>
                    <span class="account">尚未連結帳戶</span>
                </el-radio>
                <el-radio v-model="radio1" label="4" border>
                    <img src="@/assets/images/feedback-4.png"><br>
                    <span class="account">已連結帳戶 0912345678</span>
                </el-radio>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'settingFeedback',
    data() {
        return {
            radio1: '1',
        };
    },
    components: {

    },
    methods: {
        submit(){
            return this.$router.go(-1);
        },
        goback(){
            return this.$router.go(-1);
        },
    }
}
</script>