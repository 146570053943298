<template>
  <div class="main no-padding">
    <div class="dark">
        <div class="top_area">
            <div class="container">
                <div class="title">登錄兌換序號
                    <button @click="goback()" class="btn_close"></button>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="inputs">
                    <input class="ticket" placeholder="兌換序號">
                </div>
                <div class="comment">
                    <ol>
                        <li>請確認輸入的序號是否正確。</li>
                        <li>序號請妥善保管，若序號被他人盜用、兌換或是逾期會無法使用，因為以上原因則恕不補發。</li>
                        <li>若是兌換成 Ocoin，將會是有使用期限的「限時 Ocoin」。可以在 Ocoin 使用之歷史紀錄做查詢。</li>
                        <li>本公司保留修改、變更規定及終止相關辦法之權利。</li>
                    </ol>
                </div>
                <div class="btns">
                    <button @click="submit()" class="btn_submit">送出</button>
                </div>
            </div> <!--end of main-->
        </div> <!--end of content-->
    </div> <!--end of dark-->
  </div> <!--end of main-->
</template>

<script>
export default {
    name: 'exchangeNumber',
    data() {
        return {
        };
    },
    components: {

    },
    methods: {
        submit(){
            return this.$router.go(-1);
        },
        goback(){
            return this.$router.go(-1);
        }
    }
}
</script>