<template>
  <div class="main no-padding">
    <div class="dark">
        <div class="top_area">
            <div class="container">
                <div class="title">邀請好友賺DAOcoin
                    <button @click="goback()" class="btn_close"></button>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="comment center">
                    <div class="img">
                        <div class="user_img"><img src="@/assets/images/footer-icon-5.svg"></div>
                        <div class="bubble"></div>
                        <div class="bubble b2"></div>
                        <div class="bubble b3"></div>
                        <div class="bubble b4"></div>
                    </div>
                    <h3>獎勵活動已經結束，敬請期待下一波活動！</h3>
                    <p>邀請越多好友，拿越多回饋獎勵</p>
                </div>
                <div class="inputs">
                    <div class="btns">
                        <button @click="submit()" class="btn_submit">立馬邀請好友</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'invite',
    data() {
        return {
        };
    },
    components: {

    },
    mounted() {
        const bubble = document.createElement('div');
        bubble.className = 'bubble';
        this.$el.appendChild(bubble);
    },
    methods: {
        submit(){
            return this.$router.go(-1);
        },
        goback(){
            return this.$router.go(-1);
        }
    }
}
</script>