<template>
  <div class="main dark_mode">
    <div class="dark">
        <div class="top_area fix_top">
            <div class="member_tab">
                <ul>
                    <li><router-link to="/member-card">會員卡</router-link></li>
                    <li class="active">禮物券</li>
                    <li><router-link to="/brand">品牌牆</router-link></li>
                </ul>
            </div>
        </div> <!--end of top_bar-->
        <div class="content">
            <div class="container">
                <div class="gift_coupon">
                    <ul>
                        <li class="expired">
                            <router-link to='/coupon-info'>
                            <div class="logo">
                                <img src="@/assets/images/member-logo-1.png"> 餡老滿
                            </div>
                            <div class="img">
                                <div class="cover_expire"><img src="@/assets/images/word-expire.svg"></div>
                                <img src="@/assets/images/idx-slider-10.jpg">
                            </div>
                            <div class="text">
                                <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                                <span class="sub"><img src="@/assets/images/icon-close-gray.svg"> 已於 2024/4/30 23:59 到期</span>
                            </div>
                            </router-link>
                        </li>
                        <li>
                            <div class="logo">
                                <img src="@/assets/images/member-logo-2.png"> 築間餐飲集團
                            </div>
                            <div class="img"><img src="@/assets/images/idx-slider-11.jpg"></div>
                            <div class="text">
                                <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                                <span class="sub"><img src="@/assets/images/icon-clock.svg"> 2024/6/3 23:59 到期</span>
                            </div>
                        </li>
                        <li>
                            <div class="logo">
                                <img src="@/assets/images/member-logo-3.png"> 孫東寶 台式牛排教父
                            </div>
                            <div class="img"><img src="@/assets/images/idx-slider-12.jpg"></div>
                            <div class="text">
                                <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                                <span class="sub"><img src="@/assets/images/icon-clock.svg"> 2024/6/3 23:59 到期</span>
                            </div>
                        </li>
                        <li>
                            <div class="logo">
                                <img src="@/assets/images/member-logo-1.png"> 餡老滿
                            </div>
                            <div class="img"><img src="@/assets/images/idx-slider-1.jpg"></div>
                            <div class="text">
                                <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                                <span class="sub"><img src="@/assets/images/icon-clock.svg"> 2024/6/3 23:59 到期</span>
                            </div>
                        </li>
                        <li>
                            <div class="logo">
                                <img src="@/assets/images/member-logo-2.png"> 築間餐飲集團
                            </div>
                            <div class="img"><img src="@/assets/images/idx-slider-2.jpg"></div>
                            <div class="text">
                                <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                                <span class="sub"><img src="@/assets/images/icon-clock.svg"> 2024/6/3 23:59 到期</span>
                            </div>
                        </li>
                        <li>
                            <div class="logo">
                                <img src="@/assets/images/member-logo-3.png"> 孫東寶 台式牛排教父
                            </div>
                            <div class="img"><img src="@/assets/images/idx-slider-3.jpg"></div>
                            <div class="text">
                                <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                                <span class="sub"><img src="@/assets/images/icon-clock.svg"> 2024/6/3 23:59 到期</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div> <!--end of container-->
        </div> <!--end of content-->
    </div>
    <FooterMenu :darkMode="isDarkMode" />
  </div>
</template>

<script>

import FooterMenu from '@/components/Footer.vue'
export default {
  name: 'giftCoupon',
  data() {
    return {
        isDarkMode: true
    };
  },
  components: {
    FooterMenu,
  },
  methods: {
    
  }
}
</script>