<template>
    <div class="introduce">
        <div class="close_bar">
            <div class="container">
                <button @click="goback" class="btn_close"></button>
            </div>
        </div>
        <div class="block_top">
            <div class="container">
                <div class="top_bar">
                    <div class="logo">
                        <router-link to="/"><img src="@/assets/images/logo_words.svg"></router-link>
                    </div>
                    <nav>
                        <ul v-if="!isMobileMenu">
                            <li><a @click.prevent="scrollToSection('intro')">計劃簡介</a></li>
                            <li><a @click.prevent="scrollToSection('participate')">如何參與計畫</a></li>
                            <li><a @click.prevent="scrollToSection('earn')">如何賺取</a></li>
                            <li><a @click.prevent="scrollToSection('use')">如何使用</a></li>
                            <li><a @click.prevent="scrollToSection('faq')">常見問題</a></li>
                        </ul>
                        <div v-else>
                            <button @click="toggleMobileMenu" class="hamburger">&#9776;</button>
                            <ul v-if="mobileMenuOpen" class="mobile-menu">
                                <li><a @click.prevent="scrollToSection('intro')">計劃簡介</a></li>
                                <li><a @click.prevent="scrollToSection('participate')">如何參與計畫</a></li>
                                <li><a @click.prevent="scrollToSection('earn')">如何賺取</a></li>
                                <li><a @click.prevent="scrollToSection('use')">如何使用</a></li>
                                <li><a @click.prevent="scrollToSection('faq')">常見問題</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div class="left">
                    <h1>多元回饋任你選<br>消費兌點不受限</h1>
                    <button class="btn_top">立即綁定想要的回饋</button>
                </div>
                <div class="right">
                    <img class="main_img" src="@/assets/images/intro-img-1.png">
                </div>
            </div>
        </div>
        <div class="block_plan" id="intro">
            <div class="container">
                <div class="box">
                    <h1>顛覆過往點數應用情境，打造嶄新點數回饋體驗</h1>
                    <div class="scroll">
                        <div class="plan">
                            <div class="left">
                                <h2>Ocard Rewards 回饋計畫</h2>
                                <ul>
                                    <li>
                                        <div class="img"><img src="@/assets/images/icon-plan-1.svg"></div>
                                        <div class="text">
                                            <h3>自由選擇回饋</h3>
                                            <p>整合航空里數、超商點數、百貨等多元點數平台，隨時更換你想累積的回饋！</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="img"><img src="@/assets/images/icon-plan-2.svg"></div>
                                        <div class="text">
                                            <h3>多元回饋一次用</h3>
                                            <p>不同回饋餘額一次查看，還能混合多種回饋來兌換商品或折抵消費，將回饋獎勵價値最大化！</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="img"><img src="@/assets/images/icon-plan-3.svg"></div>
                                        <div class="text">
                                            <h3>回饋累兌無國界</h3>
                                            <p>線上、線下消費都能累積、兌換回饋，首創跨國、跨產業、跨品牌的回饋機制！</p>
                                        </div>
                                    </li>
                                </ul>
                            </div> <!-- end of left-->
                            <div class="right">
                                <h2>過往點數回饋計畫</h2>
                                <ul>
                                    <li>
                                        <div class="img"><img src="@/assets/images/icon-plan-4.png"></div>
                                        <div class="text">
                                            <h3>回饋類型單一</h3>
                                            <p>只能回饋該品牌或是平台推出的點數，無提供其他的點數回饋可選擇。</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="img"><img src="@/assets/images/icon-plan-5.png"></div>
                                        <div class="text">
                                            <h3>點數分散難管理</h3>
                                            <p>只能到各平台的網站或 App 查詢點數，容易遺忘使用頻率低的點數，造成點數未使用就失效。</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="img"><img src="@/assets/images/icon-plan-6.png"></div>
                                        <div class="text">
                                            <h3>流通通路受限</h3>
                                            <p>只能在單一店家或通路賺取、使用回饋，點數無法跨品牌、跨產業、跨國應用。</p>
                                        </div>
                                    </li>
                                </ul>
                            </div> <!-- end of right-->
                        </div>
                    </div>
                </div>
            </div>
        </div> <!--end of block_plan-->

        <div class="block_info">
            <div class="container">
                <h1>DAO Rewards 多元回饋獎勵任你選！</h1>
                <div class="left">
                    <img src="@/assets/images/coin-doucoing.svg">
                </div>
                <div class="right">
                    <h2>DAOCoin</h2>
                    <p>DAOCoin 是由 DAO 所推出的回饋點數，在你日常消費、品嚐美食時，就能輕鬆賺取回饋，讓你享樂到哪回饋就到哪！Ocoin 不僅可以兌換各式票券或點數，也可到Ocard 特約店家折抵消費或兌店家商品！</p>
                </div>
            </div>
        </div> <!--end of block_info-->

        <div class="block_steps" id="participate">
            <div class="container">
                <h1>4 步驟，輕鬆開啟你的 DAO Rewards 回饋之旅</h1>
                <div class="desktop">
                    <div class="step">
                        <h4>STEP 1</h4>
                        <p>下載/打開 DAOCoin App</p>
                        <div class="img">
                            <img src="@/assets/images/intro-img-2.png">
                            <div class="btn">
                                <p>透過 DAOCoin 來賺取或兌換使用 DAO Rewards 的回饋獎勵。</p>
                                <button>立即下載 <img src="@/assets/images/icon-next.svg"></button>
                            </div>
                        </div>
                    </div>
                    <div class="step">
                        <h4>STEP 2</h4>
                        <p>連結帳戶，選擇回饋</p>
                        <div class="img">
                            <img src="@/assets/images/intro-img-3.png">
                            <div class="btn">
                                <p>到「我的」一「設定回饋獎勵」，選擇想要的回饋獎勵，完成帳戶連結綁定</p>
                                <button>立即連結 <img src="@/assets/images/icon-next.svg"></button>
                            </div>
                        </div>
                    </div>
                    <div class="step">
                        <h4>STEP 3</h4>
                        <p>探索 DAOCoin 特約店家</p>
                        <div class="img">
                            <img src="@/assets/images/intro-img-3.png">
                            <div class="btn">
                                <p>在探索頁直接搜尋，或從附近/熱門店家找到想吃的美食。</p>
                                <button>立即探索 <img src="@/assets/images/icon-next.svg"></button>
                            </div>
                        </div>
                    </div>
                    <div class="step">
                        <h4>STEP 4</h4>
                        <p>查看回饋獎勵</p>
                        <div class="img">
                            <img src="@/assets/images/intro-img-3.png">
                            <div class="btn">
                                <p>賺取明細、餘額輕鬆查，一鍵查看點數與兌換紀錄。</p>
                                <button>立即查看 <img src="@/assets/images/icon-next.svg"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <VueSlickCarousel v-bind="settings" class="mobile">
                    <div class="step">
                        <h4>STEP 1</h4>
                        <p>下載/打開 DAOCoin App</p>
                        <div class="img">
                            <img src="@/assets/images/intro-img-2.png">
                            <div class="btn">
                                <p>透過 DAOCoin 來賺取或兌換使用 DAO Rewards 的回饋獎勵。</p>
                                <button>立即下載 <img src="@/assets/images/icon-next.svg"></button>
                            </div>
                        </div>
                    </div>
                    <div class="step">
                        <h4>STEP 2</h4>
                        <p>連結帳戶，選擇回饋</p>
                        <div class="img">
                            <img src="@/assets/images/intro-img-3.png">
                            <div class="btn">
                                <p>到「我的」一「設定回饋獎勵」，選擇想要的回饋獎勵，完成帳戶連結綁定</p>
                                <button>立即連結 <img src="@/assets/images/icon-next.svg"></button>
                            </div>
                        </div>
                    </div>
                    <div class="step">
                        <h4>STEP 3</h4>
                        <p>探索 DAOCoin 特約店家</p>
                        <div class="img">
                            <img src="@/assets/images/intro-img-3.png">
                            <div class="btn">
                                <p>在探索頁直接搜尋，或從附近/熱門店家找到想吃的美食。</p>
                                <button>立即探索 <img src="@/assets/images/icon-next.svg"></button>
                            </div>
                        </div>
                    </div>
                    <div class="step">
                        <h4>STEP 4</h4>
                        <p>查看回饋獎勵</p>
                        <div class="img">
                            <img src="@/assets/images/intro-img-3.png">
                            <div class="btn">
                                <p>賺取明細、餘額輕鬆查，一鍵查看點數與兌換紀錄。</p>
                                <button>立即查看 <img src="@/assets/images/icon-next.svg"></button>
                            </div>
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
        </div> <!--end of block_steps-->

        <div class="block_tabs" id="earn">
            <div class="container">
                <div class="left">
                    <h1>如何賺取回饋獎勵？</h1>
                </div>
                <div class="right">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="到店消費" name="first">
                            <div class="text">
                                <h2>到店消費賺回饋</h2>
                                <p>找到喜歡且可賺取回饋的 Ocard 特約店家，到店消費並提供手機號碼給店家，即可獲得回饋</p>
                            </div>
                            <div class="img">
                                <img src="@/assets/images/intro-img-6.png">
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="線上點" name="second">
                            <div class="text">
                                <h2>線上點賺回饋<span>（部分國家支援）</span></h2>
                                <p>在有線上點外帶外送且可賺回饋的店家下單，待訂單完成後，即可獲得回饋。</p>
                            </div>
                            <div class="img">
                                <img src="@/assets/images/intro-img-7.png">
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="購買票券" name="third">
                            <div class="text">
                                <h2>購買票券賺回饋<span>（部分國家支援）</span></h2>
                                <p>到 「Ocard 歐享券商城」購買票券，至店家使用核銷後，即可獲得回饋。</p>
                            </div>
                            <div class="img">
                                <img src="@/assets/images/intro-img-8.png">
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div> <!--end of block_tabs-->

        <div class="block_tabs second" id="use">
            <div class="container">
                <div class="left">
                    <h1>如何使用回饋獎勵？</h1>
                </div>
                <div class="right">
                    <el-tabs v-model="activeName2" @tab-click="handleClick">
                        <el-tab-pane label="消費折抵" name="first">
                            <div class="text">
                                <h2>消費折抵</h2>
                                <p>到 Ocard 特約店家，可自由選擇多種回饋來折抵消費，折抵金額無上限。</p>
                            </div>
                            <div class="img">
                                <img src="@/assets/images/intro-img-9.png">
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="商品兌換" name="second">
                            <div class="text">
                                <h2>商品兌換</h2>
                                <p>到 Ocard 特約店家，用少少的回饋獎勵就能兌換到店家提供的指定商品！。</p>
                            </div>
                            <div class="img">
                                <img src="@/assets/images/intro-img-10.png">
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="票券兌換" name="third">
                            <div class="text">
                                <h2>票券兌換<span>（部分國家支援）</span></h2>
                                <p>提供各大超商、美食店家、百貨公司等票券，多元兌點選擇任你挑！</p>
                            </div>
                            <div class="img">
                                <img src="@/assets/images/intro-img-11.png">
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div> <!--end of block_tabs-->

        <div class="block_faq" id="faq">
            <div class="container">
                <h1>Ocard Rewards 常見問題</h1>
                <el-collapse v-model="faq" accordion>
                    <el-collapse-item name="1">
                        <template slot="title">
                            Ocard Rewards 回饋獎勵的計算方式？
                        </template>
                        <div>不論選擇哪種回饋獎勵，回饋計算方式為「消費金額*回饋%數」，總回饋點數若遇小數點則無條件捨去，若消費金額不足，則無法賺取回饋獎勵。舉例來說，假設選擇 Ocoin 回饋方式，回饋為3%、消費金額為＄315，可賺取的回饋為315*3%=9.45，實際獲得回饋為9 Ocoin；若消費金額為＄20，可賺取的回饋為20*3%=0.6，實際獲得回饋為Ocoin。</div>
                    </el-collapse-item>
                    <el-collapse-item name="2">
                        <template slot="title">
                            Ocard Rewards 回饋獎勵實際存入帳戶時間？
                        </template>
                        <div>目前 Ocard Rewards 可用三種方式累積回饋，到店消費、線上點（僅限台灣）、購買票券（僅限台灣），都可以獲得回饋。</div>
                        <div>• 到店消費：至店家消費，結帳時提供手機號碼給店家，7個工作天內會獲取回饋。<br>
                        • 線上點：完成線上點外帶外送後，訂單狀態顯示「完成」後，7個工作天內可獲取回饋。<br>
                        • 購買票券：到「Ocard 歐享券商城」購買票券，至店家使用核銷後，7個工作天內可享有回饋。</div>
                        <div>可至 Ocard App 中的「回饋獎勵明細」中，確認回饋獎勵狀態，若顯示為「審核中」或「處理中」，則該筆消費的點數仍在匯入帳戶中，完成後即會顯示點數；若顯示為其他狀態，或超過7個工作天後仍未獲取點數，則可與 Ocard 客服聯繫：（+886 02-7751-5407。</div>
                    </el-collapse-item>
                    <el-collapse-item name="3">
                        <template slot="title">
                            如何辨別 Ocard Rewards 店家？
                        </template>
                        <div>在 Ocard App 搜尋時，若店家有顯示「到店/線上點✕％ 回饋」則為Ocard Rewards 店家，消費後皆可累積回饋。店家資訊頁中也會顯示為「到店消費回饋／線上點回饋」。</div>
                    </el-collapse-item>
                    <el-collapse-item name="4">
                        <template slot="title">
                            Ocard Rewards 回饋獎勵的效期？
                        </template>
                        <div>依據你選擇的回饋獎勵，會有不同的效期，Ocard所推出的Ocoin 點數，使用期限為一年，從發放點數當月起，至隔年該月月底有效，如：2023/4/1～2023/4/30 累積之點數，皆須於2024/4/30前使用完畢・其餘Ocard Rewards 合作夥伴，可以參考各夥伴官網敘述（國泰-亞洲萬里通、OPENPOINT • HAPPY GO)</div>
                    </el-collapse-item>
                </el-collapse>    
            </div>
        </div> <!--end of block_faq-->

        <div class="block_foot">
            <div class="container">
                <div class="left">
                    <div class="logo">
                        <img src="@/assets/images/logo_words.svg">
                    </div>
                    <div class="download">
                        <button><img src="@/assets/images/intro-dw-1.svg"></button>
                        <button><img src="@/assets/images/intro-dw-2.svg"></button>
                    </div>
                </div>
                <div class="right">
                    <div class="social">
                        <button><img src="@/assets/images/intro-foot-1.svg"></button>
                        <button><img src="@/assets/images/intro-foot-2.svg"></button>
                        <button><img src="@/assets/images/intro-foot-3.svg"></button>
                    </div>
                    <div class="info">
                        週一至週五 10:00~18:30<br>
                        GMT+8，Taiwan<br>
                        +886-2-7751-5407<br>
                        service@ocard.co<br>
                        隱私權政策・使用者條款
                    </div>
                </div>
            </div>
        </div> <!--end of block_foot-->

     </div> 
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    data() {
        return {
            isMobileMenu: window.innerWidth <= 1024,
            mobileMenuOpen: false,
            settings: {
                dots: false,
                focusOnSelect: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                touchThreshold: 1,
                autoplay: false,
            },
            activeName: 'first',
            activeName2: 'first',
            faq: '1',
        }
    },
    components: {
        VueSlickCarousel
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        goback(){
            return this.$router.go(-1);
        },
        toggleMobileMenu() {
            this.mobileMenuOpen = !this.mobileMenuOpen;
        },
        scrollToSection(id) {
            const section = document.getElementById(id);
            if (section) {
                const offsetTop = section.offsetTop;
                window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
                });
            }
            this.mobileMenuOpen = false;
        },
        handleResize() {
            this.isMobileMenu = window.innerWidth <= 1024;
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style>

</style>