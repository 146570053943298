<template>
  <div class="main no-padding">
    <div class="dark">
        <div class="top_area">
            <div class="container">
                <div class="title">修改個人資料
                    <button @click="goback()" class="btn_close"></button>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="user_img">
                    <el-upload
                        class="avatar-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i class="btn_upload_s el-icon-camera-solid avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div class="inputs">
                    <div class="input half">
                        <label>姓氏</label>
                        <input class="name" @click="dialog_gender = true" type="button" >
                    </div>
                    <div class="input half">
                        <label>名字</label>
                        <input type="text">
                    </div>
                    <div class="input">
                        <label>出生年月日</label>
                        <input class="birthday" @click="openPicker()" type="button" :value="pickerDate">
                        <mt-datetime-picker
                            class="datePicker"
                            ref="datePicker"
                            type="date"
                            :startDate="new Date(1900,1,1)"
                            year-format="{value} 年"
                            month-format="{value} 月"
                            date-format="{value} 日"
                            confirmText="確認"
                            cancelText="取消"
                            v-model="pickerDateValue"
                            @confirm="handleDateConfirm">
                        </mt-datetime-picker>
                        <!-- <mt-datetime-picker
                            ref="picker"
                            type="time"
                            v-model="birthday">
                        </mt-datetime-picker> -->
                    </div>
                    <div class="input">
                        <label>性別</label>
                        <input class="gender" type="button" @click="openGenderDialog" :value="gender.value">
                    </div>
                    <div class="btns">
                        <button class="btn_delete btn_s" @click="gotoDeleteAccount">請求刪除會員</button>
                        <button @click="submit()" class="btn_submit">送出修改</button>
                    </div>
                </div> <!--end of inputs-->
            </div> <!--end of container-->
        </div> <!--end of content-->
        <el-drawer
            title="請選擇"
            :visible.sync="dialog_gender"
            direction="btt"
            custom-class="demo-drawer"
            ref="drawer"
            size="25%"
            >
            <div class="demo-drawer__content">
                <el-radio-group v-model="selectedGender">
                    <el-radio label="男性">男性</el-radio>
                    <el-radio label="女性">女性</el-radio>
                </el-radio-group>
            </div>
        </el-drawer> 

        <el-drawer
            title="請選擇"
            :visible.sync="dialog"
            direction="btt"
            custom-class="demo-drawer"
            ref="drawer"
            size="35%"
            >
            <div class="demo-drawer__content">
                <ul>
                    <li><button class="btn_normal">從相片集</button></li>
                    <li><button class="btn_normal">拍照</button></li>
                    <li><button class="btn_cancel" @click="dialog = false">取消</button></li>
                </ul>
            </div>
        </el-drawer> 
    </div>
  </div>
</template>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
<script>
export default {
    name: 'editProfile',
    data() {
        return {
            imageUrl: '',
            pickerDateValue: new Date(1988,0,1),
            pickerDate: '',
            dialog: false,
            dialog_gender: false,
            selectedGender: null,
            gender: {
                value: '' // 设置一个默认值
            }
        };
    },
    components: {

    },
    methods: {
        submit(){
            return this.$router.go(-1);
        },
        goback(){
            return this.$router.go(-1);
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        openPicker() {
            this.$refs.datePicker.open();
        },
        handleDateConfirm(){
            this.pickerDate = this.formatDate(this.pickerDateValue);
            console.log('pickerDateValue:', this.formatDate(this.pickerDateValue));
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        openGenderDialog() {
            this.dialog_gender = true;
        },
        gotoDeleteAccount() {
            return this.$router.push('delete-account')
        }
    },
    watch: {
        selectedGender(newValue) {
            // 监听选中的性别值的变化
            this.gender.value = newValue;
            this.dialog_gender = false; // 关闭弹出框
        }
    }
    
}
</script>