<template>
  <div class="main">
    <div class="coin page">
      <div class="top_bar">
        <span class="title">DAOcoin 餘額</span>
        <span class="num">20</span>
        <div class="coin_logo"><img src="@/assets/images/coin-doucoing.svg"></div> 
      </div> <!--end of top_bar-->
      <div class="bottom_bar">
        <div class="menu">
          <button @click="gotoFeedback"><img src="@/assets/images/footer-icon-2.svg"><br>明細紀錄</button>
          <button @click="gotoExchangeToDao"><img src="@/assets/images/footer-icon-2.svg"><br>轉成 DaoCoin</button>
          <button @click="gotoIntroduce"><img src="@/assets/images/icon-info.svg"><br>了解 DaoCoin</button>
        </div> <!--end of menu-->
        <div class="banner">
          <VueSlickCarousel :arrows="false" :dots="true" :infinite="true" :autoplay="true">
            <div class="img"><img src="@/assets/images/idx-slider-10.jpg"></div>
            <div class="img"><img src="@/assets/images/idx-slider-11.jpg"></div>
            <div class="img"><img src="@/assets/images/idx-slider-12.jpg"></div>
          </VueSlickCarousel>
        </div> <!--end of banner-->

        <div class="new list">
          <h1>新進店家 <button class="btn_more">查看全部</button></h1>
          <VueSlickCarousel v-bind="settings_list">
          <div class="card" v-for="(item, index) in new_items" :key="index">
            <div class="img"><img :src="item.image" alt="carousel-item"></div>
            <div class="shop_info">
              <div class="tag">{{ item.tag }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="info">
                <span class="star">{{ item.star }}</span>
                <span class="location">{{ item.location }}</span>
                <span class="pointback">{{ item.pointback }}</span>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        </div> <!--end of new-->

        <h1 class="padding-left">任務專區 <button class="btn_more" @click="gotoMission">查看全部</button></h1>
        <div class="top_card">
            <ul>
                <li>
                  <router-link to="/mission-content">
                    <div class="img"><img src="@/assets/images/img-tea.jpg"></div>
                    <div class="text">下午茶達人
                        <span class="sub">最多享 240 Ocoin</span>
                    </div>
                  </router-link>
                </li>
                <li>
                    <div class="img"><img src="@/assets/images/img-brunch.jpg"></div>
                    <div class="text">早午餐達人
                        <span class="sub">最多享 240 Ocoin</span>
                    </div>
                </li>
                <li>
                    <div class="img"><img src="@/assets/images/img-drink.jpg"></div>
                    <div class="text">手搖達人
                        <span class="sub">最多享 150 Ocoin</span>
                    </div>
                </li>
                <li>
                    <div class="img"><img src="@/assets/images/img-tea.jpg"></div>
                    <div class="text">下午茶達人
                        <span class="sub">最多享 240 Ocoin</span>
                    </div>
                </li>
                <li>
                    <div class="img"><img src="@/assets/images/img-brunch.jpg"></div>
                    <div class="text">早午餐達人
                        <span class="sub">最多享 240 Ocoin</span>
                    </div>
                </li>
                <li>
                    <div class="img"><img src="@/assets/images/img-drink.jpg"></div>
                    <div class="text">手搖達人
                        <span class="sub">最多享 150 Ocoin</span>
                    </div>
                </li>
            </ul>
        </div>

        <h1 class="padding-left">線上兌換 <button class="btn_more">查看全部</button></h1>
        <div class="exchange">
          <VueSlickCarousel v-bind="settings_list_small">
            <div class="box">
              <router-link to="exchange-store">
                <div class="img">
                  <img src="@/assets/images/online-1.png">
                </div>
              </router-link>
              <div class="text">
                台灣大車隊<br>
                <span>台灣計程車第一品牌</span>
              </div>
            </div>
            <div class="box">
              <div class="img">
                <img src="@/assets/images/online-2.png">
              </div>
              <div class="text">
                CoCo都可<br>
                <span>傳遞微笑的手搖飲</span>
              </div>
            </div>
            <div class="box">
              <div class="img">
                <img src="@/assets/images/online-3.png">
              </div>
              <div class="text">
                SUSHiPLUS<br>
                <span>升級版爭鮮迴轉壽司</span>
              </div>
            </div>
            <div class="box">
              <div class="img">
                <img src="@/assets/images/online-4.png">
              </div>
              <div class="text">
                爭鮮<br>
                <span>台灣最大迴轉壽司品牌</span>
              </div>
            </div>
            <div class="box">
              <div class="img">
                <img src="@/assets/images/online-5.png">
              </div>
              <div class="text">
                爭鮮<br>
                <span>新幹線列車直送餐點</span>
              </div>
            </div>
            <div class="box">
              <div class="img">
                <img src="@/assets/images/online-6.png">
              </div>
              <div class="text">
                定食8<br>
                <span>平價美味的日式定食</span>
              </div>
            </div>
            <div class="box">
              <div class="img">
                <img src="@/assets/images/online-7.png">
              </div>
              <div class="text">
                美廉社<br>
                <span>物美價廉</span>
              </div>
            </div>
            <div class="box">
              <div class="img">
                <img src="@/assets/images/online-8.png">
              </div>
              <div class="text">
                愛買<br>
                <span>天天愛買天天滿足</span>
              </div>
            </div>
          </VueSlickCarousel>
        </div> <!--end of exchange-->

        <h1 class="padding-left">平台兌換 <button class="btn_more">查看全部</button></h1>
        <div class="exchange">
          <VueSlickCarousel v-bind="settings_list_xsmall">
            <div class="box">
              <router-link to="exchange-platform">
                <div class="img">
                  <img src="@/assets/images/transfer-1.png">
                </div>
              </router-link>
              <div class="text">
                全家便利商店<br>
                <span>Fa 點</span>
              </div>
            </div>
            <div class="box">
              <div class="img">
                <img src="@/assets/images/transfer-2.png">
              </div>
              <div class="text">
                樂天市場<br>
                <span>樂天點數</span>
              </div>
            </div>
            <div class="box">
              <div class="img">
                <img src="@/assets/images/transfer-3.png">
              </div>
              <div class="text">
                SUPER POINT<br>
                <span>點數是你第二個錢包</span>
              </div>
            </div>
            <div class="box">
              <div class="img">
                <img src="@/assets/images/transfer-4.png">
              </div>
              <div class="text">
                「國泰 - 亞洲萬里通」<br>
                <span>旅遊及消閒獎勵計劃</span>
              </div>
            </div>
          </VueSlickCarousel>
        </div>

      </div> <!--end of bottom_bar-->
    </div> <!--end of coin-->
    <FooterMenu />
  </div> <!--end of main-->
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import FooterMenu from '@/components/Footer.vue'
export default {
  name: 'DaoCoin',
  data() {
    return {
      settings_list: {
          focusOnSelect: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          touchThreshold: 2,
      },
      settings_list_small: {
          focusOnSelect: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          touchThreshold: 3,
          rows: 2,
          slidesPerRow: 1
      },
      settings_list_xsmall: {
          focusOnSelect: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          touchThreshold: 3,
          rows: 2,
          slidesPerRow: 1
      },
      new_items: [
        { 
          image: require('@/assets/images/idx-slider-12.jpg'),
          name: '新進店家1',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        { 
          image: require('@/assets/images/idx-slider-1.jpg'),
          name: '新進店家2',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        { 
          image: require('@/assets/images/idx-slider-2.jpg'),
          name: '新進店家3',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        { 
          image: require('@/assets/images/idx-slider-3.jpg'),
          name: '新進店家4',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        // Add more carousel items as needed
      ],
      newCurrentIndex: 0,
      online_exchange: [],
      platform_exchange: [],
    };
  },
  components: {
    FooterMenu, 
    VueSlickCarousel
  },
  methods: {
    gotoMission() {
      return this.$router.push('mission')
    },
    gotoFeedback() {
      return this.$router.push('feedback-detail')
    },
    gotoIntroduce() {
      return this.$router.push('introduce')
    },
    gotoExchangeToDao() {
      return this.$router.push('exchange-to-dao')
    },
    updateSettingsForScreenWidth() {
      if (window.innerWidth > 1680) {
        //一般
        this.settings_list.slidesToShow = 4;
        this.settings_list.touchThreshold = 5;
      } else if (window.innerWidth > 1280) {
        //一般
        this.settings_list.slidesToShow = 3;
        this.settings_list.touchThreshold = 4;
        //較小
        this.settings_list_small.rows = 1;
      } else if (window.innerWidth > 980) {
        //較小
        this.settings_list_small.slidesToShow = 4;
        this.settings_list_small.touchThreshold = 5;
        this.settings_list_small.rows = 2;
        this.settings_list_small.slidesPerRow = 1;
        //最小
        this.settings_list_xsmall.slidesToShow = 4;
        this.settings_list_xsmall.rows = 1;
      } else if (window.innerWidth > 600) {
        //一般
        this.settings_list.slidesToShow = 2;
        this.settings_list.touchThreshold = 3;
        //較小
        this.settings_list_small.slidesToShow = 3;
        this.settings_list_small.touchThreshold = 4;
        //最小
        this.settings_list_xsmall.slidesToShow = 3;
        this.settings_list_xsmall.rows = 1;
      } else {
        //一般
        this.settings_list.slidesToShow = 1;
        this.settings_list.touchThreshold = 2;
        //較小
        this.settings_list_small.slidesToShow = 2;
        this.settings_list_small.touchThreshold = 3;
        this.settings_list_small.rows = 2;
        this.settings_list_small.slidesPerRow = 1;
        //最小
        this.settings_list_xsmall.slidesToShow = 2;
        this.settings_list_xsmall.touchThreshold = 3;
        this.settings_list_xsmall.rows = 2;
        this.settings_list_xsmall.slidesPerRow = 1;
      }
    }
  },
  mounted() {
    this.updateSettingsForScreenWidth();
    window.addEventListener('resize', this.updateSettingsForScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSettingsForScreenWidth);
  },
}
</script>