<template>
    <div class="online white_drawer">
        <div class="close_bar">
            <div class="container">
                <button @click="goback" class="btn_close"></button>
            </div>
        </div>
        <div class="top_bar">
            <div class="container">
                <button class="btn_back" @click="goback"></button>
                <button class="btn_language">繁體中文</button>
                <button class="btn_user" @click="dialog_menu = true"><img src="@/assets/images/idx-slider-3.jpg"></button>
            </div>
        </div>

        <div class="order">
            <div class="container">
                <h2>未結帳訂單</h2>
                <ul>
                    <li>
                        <div class="img"><i class="el-icon-document"></i></div>
                        <div class="text">
                            <h3>店家名稱</h3>
                            <span class="price">$0</span>
                        </div>
                    </li>
                </ul>

                <h2>進行中訂單</h2>
                <div class="empty">尚無進行中訂單</div>

                <h2>過往訂單</h2>
                <div class="empty">尚無進行中訂單</div>
            </div>
        </div>

        <!--選單-->
        <OnlineMenu :dialog_menu.sync="dialog_menu" @go-faq="goFAQ" />
    </div>
</template>

<script>
import OnlineMenu from '@/components/OnlineMenu.vue'
export default {
    data() {
      return {
        dialog_menu: false,
        activeNames: ['1']
      }
    },
    components: {
        OnlineMenu
    },
    methods: {
        goback(){
            return this.$router.go(-1);
        },
        handleChange(val) {
            console.log(val);
        },
        goFAQ() {
            if (this.$route.path !== '/faq') {
                this.$router.push('/faq');
            }
        }
    }
}
</script>

<style>

</style>
    