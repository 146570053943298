<template>
    <div class="foot" :class="{ dark_mode: darkMode }">
        <ul class="menu">
            <li :class="{ active: $route.path === '/' }">
                <router-link to="/">
                    <img v-if="!darkMode" src="@/assets/images/footer-icon-1.svg">
                    <img v-else src="@/assets/images/footer-icon-1-w.svg"><br>探索
                </router-link></li>
            <li :class="{ active: $route.path === '/dao-coin'}">
                <router-link to="/dao-coin">
                <img v-if="!darkMode" src="@/assets/images/footer-icon-2.svg">
                <img v-else src="@/assets/images/footer-icon-2-w.svg"><br>DaoCoin
                </router-link>
            </li>
            <li :class="{ active: $route.path === '/scan'}">
                <router-link to="/scan">
                <img v-if="!darkMode" src="@/assets/images/footer-icon-3.svg">
                <img v-else src="@/assets/images/footer-icon-3-w.svg"><br>掃碼
                </router-link>
            </li>
            <li :class="{ active: $route.path === '/member-card' || $route.path === '/gift-coupon' || $route.path === '/brand' }">
                <router-link to="/member-card">
                    <img v-if="!darkMode" src="@/assets/images/footer-icon-4.svg">
                    <img v-else src="@/assets/images/footer-icon-4-w.svg"><br>會員卡
                </router-link>
            </li>
            <li :class="{ active: $route.path === '/my-page' }">
                <router-link to="/my-page">
                    <img v-if="!darkMode" src="@/assets/images/footer-icon-5.svg">
                    <img v-else src="@/assets/images/footer-icon-5-w.svg"><br>我的
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  name: 'footerMenu',
  props: {
    darkMode: Boolean
  }
}
</script>