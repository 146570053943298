<template>
    <div class="online white_drawer">
        <div class="close_bar">
            <div class="container">
                <button @click="goback" class="btn_close"></button>
            </div>
        </div>
        <div class="top_bar">
            <div class="container">
                <button class="btn_back" @click="goback"></button>
                <button class="btn_language">繁體中文</button>
                <button class="btn_user" @click="dialog_menu = true"><img src="@/assets/images/idx-slider-3.jpg"></button>
            </div>
        </div>

        <div class="faq">
            <div class="container">
                <h2>登入問題</h2>
                <el-collapse @change="handleChange">
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q1</span> 為什麼要登入？
                        </template>
                        <div>登入是為了方便您在訂購時就能使用品牌之會員點數或禮物券來兌換商品，訂購後也能累積點數或賺取回饋；也方便您查詢訂單記錄、儲存信用卡資訊，因此我們強烈建議您登入後再行訂購喔！</div>
                    </el-collapse-item>
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q2</span> 登入一次以後都不用再登入了嗎？
                        </template>
                        <div>為了資料安全，系統經過一段時間後可能會自動登出，麻煩您重新登入。</div>
                    </el-collapse-item>
                </el-collapse>    
                
                <h2>訂單問題</h2>
                <el-collapse @change="handleChange">
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q1</span> 如何査詢訂單狀態？
                        </template>
                        <div>您可以在右上角選單「我的訂單」中看到各訂單的狀態。</div>
                    </el-collapse-item>
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q2</span> 如何取消訂單？
                        </template>
                        <div>店家接單前，可以在訂單狀態內找到取消訂單鈕自由取消；訂單接單後，由於店家已經開始準備訂單，恕無法取消，若有相關需求或問題，請透過訂單資訊上的聯繫方式，自行聯繫店家處理。</div>
                    </el-collapse-item>
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q3</span> 訂單要如何更改内容？
                        </template>
                        <div>店家開始處理您的訂單前，若您需要更改訂單內容，您可以直接取消訂單，再重新下訂。</div>
                    </el-collapse-item>
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q4</span> 自取訂單的領取方式？
                        </template>
                        <div>到店後，告知店家有在Ocard 線上點預訂商品，請店員確認訂單編號及訂購人姓名即可。</div>
                    </el-collapse-item>
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q5</span> 運送訂單何時會送達？
                        </template>
                        <div>您可在訂單狀態內確認預計送達時間，惟運送過程可能因天氣、交通等因素影響，可能提早或延渥。</div>
                    </el-collapse-item>
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q6</span> 運送訂單沒有送達要如何處理？
                        </template>
                        <div>運送訂單有可能因故有所延遲，您可在訂單狀態內確認預計送達時間是否有更動，若仍有問題請直接聯繫店家提供最即時的服務。</div>
                    </el-collapse-item>
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q7</span> 訂單品項有誤我該如何處理？
                        </template>
                        <div>很抱歉造成您的不便，請您聯繫店家端提供您最即時的處理。</div>
                    </el-collapse-item>
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q8</span> 如何査看訂單記錄？
                        </template>
                        <div>在右上角選單「我的訂單」可査詢到過往訂單紀錄，若您訂購時有登入會員，即可查看您帳號下的所有訂單記錄哨！</div>
                    </el-collapse-item>
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q9</span> 商品已領取/已送達，訂單狀態卻不是「已完成」？
                        </template>
                        <div>請您直接聯繫店家，並提供您的訂單編號以利進行後續處理。</div>
                    </el-collapse-item>
                </el-collapse>

                <h2>金流付款問題</h2>
                <el-collapse @change="handleChange">
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q1</span> Ocard 線上點使用的金流是什麼？安全嗎？
                        </template>
                        <div>根據店家或付款方式選擇的不同，將使用不同的金流。</div>
                        <div>如果您選擇的店家使用 Ocard 金流，係透過喬睿科技TapPay 金流系統運作，並與台新國際商業銀行合作推出；如果您選擇的店家使用自有金流，可能係使用下列其中之一：LINE Pay、NewebPay 藍新金流、MAYPAY。以上金流之安全皆符合業界標準，請您安心使用。</div>
                        <div>若您選擇到店付款或其他付款方式，其金流運作可能不經由 Ocard 線上點，建議您在使用前可聯繫店家洽詢相關資訊。</div>
                    </el-collapse-item>
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q2</span> 使用 Ocard 線上點一定要線上預先刷卡嗎？
                        </template>
                        <div>若店家有開啟店內付款，您也可以選擇到店使用現金，或其他店家接受的付款方式。</div>
                    </el-collapse-item>
                    <el-collapse-item>
                        <template slot="title">
                            <span>Q3</span> 如何確認我的訂單付款已經成功了？
                        </template>
                        <div>當您的畫面顯示訂單狀態頁面，或是收到訂單確認電郵就代表付款成功、訂單已發送到店家端。</div>
                    </el-collapse-item>
                </el-collapse>    
            </div>
        </div>

        <!--選單-->
        <OnlineMenu :dialog_menu.sync="dialog_menu" @go-faq="goFAQ" />
    </div>
</template>

<script>
import OnlineMenu from '@/components/OnlineMenu.vue'
export default {
    data() {
      return {
        dialog_menu: false,
        activeNames: ['1']
      }
    },
    components: {
        OnlineMenu
    },
    methods: {
        goback(){
            return this.$router.go(-1);
        },
        handleChange(val) {
            console.log(val);
        },
        goFAQ() {
            if (this.$route.path !== '/faq') {
                this.$router.push('/faq');
            }
        }
    }
}
</script>

<style>

</style>
    