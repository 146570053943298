<template>
  <div class="main">
    <div class="my_page">
        <div class="top_bar">
          <div class="container">
            <div class="title">通知中心
              <button @click="goback()" class="btn_close"></button>
            </div>
          </div>
        </div> <!--end of top_bar-->
        <div class="content">
          <div class="container">
            <button class="notice" @click="dialog = true">
              <div class="logo"><img src="@/assets/images/push-logo-2.png"></div>
              <div class="text">
                <div class="name">小蒙牛餐飲集團</div>
                <p>邀請您成為會員</p>
                <div class="date">5/13</div>
              </div>
            </button>
            <button class="notice" @click="gotoCoupon">
              <div class="logo"><img src="@/assets/images/push-logo-2.png"></div>
              <div class="text">
                <div class="name">小蒙牛餐飲集團</div>
                <p>您因為「Ocard 嚐鮮券」活動收到了1張奶油明太子香魷義大利麵</p>
                <div class="date">5/13</div>
              </div>
            </button>
            <button class="notice" @click="gotoArticle">
              <div class="logo"><img src="@/assets/images/push-logo-5.png"></div>
              <div class="text">
                <div class="name">餡老滿</div>
                <p>發給您一則消息「【點10成金 】冷凍餃特價」</p>
                <div class="date">5/13</div>
              </div>
            </button>
          </div>
        </div> <!--end of content-->
    </div>
    <div class="white_drawer">
      <el-drawer
          title="收到新的會員邀請"
          :visible.sync="dialog"
          direction="btt"
          custom-class="demo-drawer"
          ref="drawer"
          size="55%"
          >
          <div class="demo-drawer__content">
            <div class="shop_brand">
              <div class="img"><img src="@/assets/images/idx-slider-6.jpg"></div>
              <div class="logo"><img src="@/assets/images/member-logo-1.png"><br>小蒙牛餐飲集團</div>
              <div class="cover">
                  <span class="info">小蒙牛餐飲集團集點會員</span>
              </div>
            </div> <!--end of shop_brand-->
            <div class="hint">點按接受，即表示您接受同意該店之隱私權條款</div>
            <div class="btns">
              <button class="btn_cancel" @click="dialog = false">先不要</button>
              <button class="btn_submit" @click="dialog = false">接受</button>
            </div>
          </div>
      </el-drawer> 
    </div>
  </div>
</template>

<script>
export default {
  name: 'notice',
  data() {
    return {
        dialog: false
    };
  },
  components: {
    
  },
  methods: {
    goback(){
        return this.$router.go(-1);
    },
    gotoCoupon(){
        return this.$router.push('coupon-info')
    },
    gotoArticle(){
        return this.$router.push('article')
    }
  }
}
</script>