<template>
  <div class="member_page">
    <div class="top_area" :style="{ 'background-image': 'url(' + require('@/assets/images/idx-slider-3.jpg') + ')' }">
      <div class="cover"></div>
      <div class="container">
        <div class="top_bar">
          <div class="title">築間餐飲集團</div>
          <button @click="goback()" class="btn_back"></button>
        </div> <!--end of top_bar-->
        <div class="card" :style="{ height: cardHeight + 'px', opacity: cardOpacity }">
          <div class="cover_text" :style="{ opacity: cardOpacity }">
            <button @click="dialog_qrcode = true" class="btn_qrcode"></button>
          </div>
          <div class="img" :style="{ opacity: cardOpacity }"><img src="@/assets/images/idx-slider-3.jpg"></div>
          <div class="logo" :style="{ opacity: cardOpacity }">
            <img src="@/assets/images/member-logo-1.png"><br>
            築間餐飲集團<br>
            <span class="sub">宣傳文字內容</span>
          </div>
        </div> <!--end of card-->
        <div class="tabs-container">
          <div
            class="tab"
            :class="{ active: activeTab === 0 }"
            @click="setActiveTab(0)"
          >
            <span class="number">0</span>
            點數
            <div v-if="activeTab === 0" class="tab-indicator"></div>
          </div>
          <div
            class="tab"
            :class="{ active: activeTab === 1 }"
            @click="setActiveTab(1)"
          >
            <span class="number">0</span>
            禮物券
            <div v-if="activeTab === 1" class="tab-indicator"></div>
          </div>
          <div
            class="tab"
            :class="{ active: activeTab === 2 }"
            @click="setActiveTab(2)"
          >
            <img :src="require('@/assets/images/icon-stamp.svg')" alt="Tab 3" class="tab-icon" />
            品牌動態
            <div v-if="activeTab === 2" class="tab-indicator"></div>
          </div>
          <div
            class="tab"
            :class="{ active: activeTab === 3 }"
            @click="setActiveTab(3)"
          >
            <img :src="require('@/assets/images/icon-store-white.svg')" alt="Tab 4" class="tab-icon" />
            品牌資訊
            <div v-if="activeTab === 3" class="tab-indicator"></div>
          </div>
        </div> <!--end of tabs-->
      </div> <!--end of container-->
    </div> <!--end of top_area-->
    <div class="bottom_area">
      <div class="container">
        <div class="tabs-container">
          <div class="tab-content">
            <div v-show="activeTab === 0" class="content-tab1">
              <!-- 點數 -->
              點數內容說明：<br>
              ①凡至【築間幸福鍋物/燒肉 Smile/ 築間酸菜魚/ 木庶韓國烤肉/木庶韓國豆腐鍋/築間麻辣火鍋】全台門店用餐，結帳金額每滿100元可累積點數1點，以此類推，點數依結帳金額累積，無累積上限。<br>
              ②凡至【有之和牛/本格和牛/給馬別邸/紫木槿韓國烤肉】全台門店用餐，結帳金額每滿200元可累積點數1點，以此類推，點數依結帳金額累積，無累積上限。<br><br>
              注意事項：<br>
              1. 當次消費累積點數，限【下次消費】兌換使用。<br>
              2. 點數效期於用餐後隔日開始計算【63天】，逾期點數將自動移除。<br>
              一針對點數到期日，以下提供舉例說明：<br>
              點數到期日若顯示為 2024/03/06，即代表可使用至<br>
              2024/03/06 23:59，2024/03/07 00:00 即失效。<br>
              3. 點數【限兌換一次】，不可重複兌換。<br>
              一築間/燒肉 Smile/ 木庶韓國烤肉/ 木庶韓國豆腐鍋/給馬別邸/紫木槿韓國烤肉【每份套餐限兌換一次】。<br>
              一築間酸菜魚【每消費400元即可兌換一次】。<br>
              一有之和牛/本格和牛【每桌限兌換一次】。<br>
              一築間麻辣火鍋【每人限兌換一次】。<br>
              4. 使用點數，須於點餐時或結帳前兌換完畢，點餐後或結帳後恕不受理。
              
              <h1>點數獎勵
                <button @click="dialog = true">{{ brand.value }}
                  <img src="@/assets/images/icon-down.svg">
                </button>
              </h1>
              <div class="point" :style="{ 'background-image': 'url(' + require('@/assets/images/idx-slider-8.jpg') + ')' }">
                <router-link to="/point-exchange-info">
                  <div class="cover_img"></div>
                  <div class="text">築間幸福鍋物 -【3點】兌換</div>
                  <div class="num"><span class="highlight">3</span>點</div>
                </router-link>
              </div>
              <div class="point" :style="{ 'background-image': 'url(' + require('@/assets/images/idx-slider-9.jpg') + ')' }">
                <div class="cover_img"></div>
                <div class="text">築間幸福鍋物-【5點】兌換</div>
                <div class="num"><span class="highlight">5</span>點</div>
              </div>
              <div class="point" :style="{ 'background-image': 'url(' + require('@/assets/images/idx-slider-10.jpg') + ')' }">
                <div class="cover_img"></div>
                <div class="text">築間幸福鍋物-【8點】兌換</div>
                <div class="num"><span class="highlight">8</span>點</div>
              </div>

              <h1>點數歷史</h1>
              <ul class="point_history">
                <li>有10點已經過期了
                  <span class="time">2024/4/4 23:59</span>
                </li>
                <li class="withContent">有10點已經過期了
                  <span class="time">2024/1/29 23:59<br>使用期限2024/4/1</span>
                  <div class="exchange_no">
                    <span class="title">交易序號</span>
                    birthday_202401290900_475
                  </div>
                </li>
                <li>有10點已經過期了
                  <span class="time">2024/4/5 23:59</span>
                </li>
                <li class="withContent">有10點已經過期了
                  <span class="time">2023/2/1 23:59<br>使用期限2023/4/1</span>
                  <div class="exchange_no">
                    <span class="title">交易序號</span>
                    birthday_202301290900_475
                  </div>
                </li>
              </ul>
              <div class="btn_more">
                <button>載入更多</button>
              </div>
            </div>
            <div v-show="activeTab === 1" class="content-tab2">
              <!-- 禮物券 -->
              <div class="gift_coupon">
                  <ul>
                      <li class="expired">
                          <router-link to="/coupon-info">
                            <div class="img">
                                <div class="cover_expire"><img src="@/assets/images/word-expire.svg"></div>
                                <img src="@/assets/images/idx-slider-10.jpg">
                            </div>
                          </router-link>
                          <div class="text">
                              <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                              <span class="sub"><img src="@/assets/images/icon-close-gray.svg"> 已於 2024/4/30 23:59 到期</span>
                          </div>
                      </li>
                      <li>
                          <div class="img"><img src="@/assets/images/idx-slider-11.jpg"></div>
                          <div class="text">
                              <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                              <span class="sub"><img src="@/assets/images/icon-clock.svg"> 2024/6/3 23:59 到期</span>
                          </div>
                      </li>
                      <li>
                          <div class="img"><img src="@/assets/images/idx-slider-12.jpg"></div>
                          <div class="text">
                              <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                              <span class="sub"><img src="@/assets/images/icon-clock.svg"> 2024/6/3 23:59 到期</span>
                          </div>
                      </li>
                      <li>
                          <div class="img"><img src="@/assets/images/idx-slider-1.jpg"></div>
                          <div class="text">
                              <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                              <span class="sub"><img src="@/assets/images/icon-clock.svg"> 2024/6/3 23:59 到期</span>
                          </div>
                      </li>
                      <li>
                          <div class="img"><img src="@/assets/images/idx-slider-2.jpg"></div>
                          <div class="text">
                              <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                              <span class="sub"><img src="@/assets/images/icon-clock.svg"> 2024/6/3 23:59 到期</span>
                          </div>
                      </li>
                      <li>
                          <div class="img"><img src="@/assets/images/idx-slider-3.jpg"></div>
                          <div class="text">
                              <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                              <span class="sub"><img src="@/assets/images/icon-clock.svg"> 2024/6/3 23:59 到期</span>
                          </div>
                      </li>
                  </ul>
              </div>
            </div>
            <div v-show="activeTab === 2" class="content-tab3">
              <!-- 品牌動態 -->
              <div class="article">
                <ul>
                  <li>         
                      <div class="img"><img src="@/assets/images/idx-slider-4.jpg"></div>
                      <div class="title">【寵愛媽咪、音樂盛宴】</div>
                      <div class="date">2024/4/26</div>
                      <div class="content">
                        <p>餡老滿~2024母親節專案</p>
                        <p>5/11（六）~5/12（日）推出特別套餐「老滿金鴨宴」，席間安排古箏現場演奏，在優雅古典氛圍中用餐，獻給媽咪幸福的音樂盛宴慰</p>
                      </div>
                      <div class="bottom_btns">
                        <button class="btn_like"></button>
                        <span class="num">2</span>
                        <router-link to="/article"><button class="btn_view">繼續閱讀</button></router-link>
                      </div>
                  </li>
                  <li>
                    <div class="img"><img src="@/assets/images/idx-slider-5.jpg"></div>
                    <div class="title">【寵愛媽咪、音樂盛宴】</div>
                    <div class="date">2024/4/26</div>
                    <div class="content">
                      <p>餡老滿~2024母親節專案</p>
                      <p>5/11（六）~5/12（日）推出特別套餐「老滿金鴨宴」，席間安排古箏現場演奏，在優雅古典氛圍中用餐，獻給媽咪幸福的音樂盛宴慰</p>
                    </div>
                    <div class="bottom_btns">
                      <button class="btn_like active"></button>
                      <span class="num">6</span>
                      <button class="btn_view">繼續閱讀</button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-show="activeTab === 3" class="content-tab4">
              <!-- 品牌資訊 -->
              <div class="top_btns">
                <ul>
                  <li><img src="@/assets/images/icon-notice-1.png"><br>官方網站</li>
                  <li><img src="@/assets/images/icon-notice-2.png"><br>Facebook</li>
                  <li><img src="@/assets/images/icon-notice-3.png"><br>Instagram</li>
                  <li><img src="@/assets/images/icon-notice-4.png"><br>線上訂位</li>
                  <li><img src="@/assets/images/icon-notice-5.png"><br>線上點</li>
                  <li><img src="@/assets/images/icon-notice-6.png"><br>其他</li>
                  <li><img src="@/assets/images/icon-notice-7.png"><br>菜單</li>
                </ul>
              </div>
              <div class="tags">
                <p>＃宮廷菜＃北京菜＃水餃＃中餐廳＃中式料理＃中式＃熱炒＃合菜＃桌菜＃年菜＃尾牙＃春酒＃包廂＃聚餐＃台北美食＃南港美食#台中美食</p>
              </div>
              <div class="text">
                <p>【餡老滿】以經營宮廷菜、特色餃子、老北京傳統菜及特色風味菜為主，特別是「老滿餃子」在清朝就深得慈禧老佛爺喜歡，特賜其名。<br>
              東元集團黃茂雄會長於2012年和北京餡老滿合作，成立京老滿餐飲股份有限公司，陸續於雙北及台中展店，秉持「餡滿心誠•賓客至上」為理念，致力重現擁有百年歷史的宮廷饗宴。</p>
              </div>
              <div class="money">$140~$600</div>
              
              <h1>分店據點</h1>
              <el-collapse v-model="shop" accordion>
                  <el-collapse-item title="頂呱呱 台北瑞光店" name="1">
                      <div>
                          <ul class="info">
                              <li>
                                  <div class="img"><img src="@/assets/images/icon-phone.svg"></div> 02-2345-5678
                              </li>
                              <li>
                                  <div class="img"><img src="@/assets/images/icon-map-2.svg"></div> 台北市內湖區瑞光路283號
                              </li>
                              <li>
                                  <div class="img"><img src="@/assets/images/icon-clock-black.svg"></div> 今日營業時間 10:30~20:00
                              </li>
                          </ul>
                      </div>
                  </el-collapse-item>
                  <el-collapse-item title="頂呱呱 松山機場店" name="2">
                      <div>
                          <ul class="info">
                              <li>
                                  <div class="img"><img src="@/assets/images/icon-phone.svg"></div> 02-2345-5678
                              </li>
                              <li>
                                  <div class="img"><img src="@/assets/images/icon-map-2.svg"></div> 台北市內湖區瑞光路283號
                              </li>
                              <li>
                                  <div class="img"><img src="@/assets/images/icon-clock-black.svg"></div> 今日營業時間 10:30~20:00
                              </li>
                          </ul>
                      </div>
                  </el-collapse-item>
                  <el-collapse-item title="頂呱呱 台北中崙店" name="3">
                      <div>
                          <ul class="info">
                              <li>
                                  <div class="img"><img src="@/assets/images/icon-phone.svg"></div> 02-2345-5678
                              </li>
                              <li>
                                  <div class="img"><img src="@/assets/images/icon-map-2.svg"></div> 台北市內湖區瑞光路283號
                              </li>
                              <li>
                                  <div class="img"><img src="@/assets/images/icon-clock-black.svg"></div> 今日營業時間 10:30~20:00
                              </li>
                          </ul>
                      </div>
                  </el-collapse-item>
                  <el-collapse-item title="頂呱呱 台北昆陽店" name="4">
                      <div>
                          <ul class="info">
                              <li>
                                  <div class="img"><img src="@/assets/images/icon-phone.svg"></div> 02-2345-5678
                              </li>
                              <li>
                                  <div class="img"><img src="@/assets/images/icon-map-2.svg"></div> 台北市內湖區瑞光路283號
                              </li>
                              <li>
                                  <div class="img"><img src="@/assets/images/icon-clock-black.svg"></div> 今日營業時間 10:30~20:00
                              </li>
                          </ul>
                      </div>
                  </el-collapse-item>
              </el-collapse>

              <h1>店家VIP</h1>
              <div class="member_type">
                <ul>
                  <li>
                    <button @click="dialog_vip = true">
                      <div class="card_img"><img src="@/assets/images/idx-slider-1.jpg"></div>
                      <div class="type">VIP</div>
                    </button>
                  </li>
                  <li><button @click="dialog_vip = true"><div class="type">VVIP</div></button></li>
                  <li><button @click="dialog_vip = true"><div class="type">企業會員</div></button></li>
                </ul>
              </div>

              <h1>會員歷史</h1>
              <ul class="point_history">
                <li>接受了會員邀請
                  <span class="time">2024/4/4 23:59</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> <!--end of container-->
    </div> <!--end of bottom_area-->
    <div class="dark_drawer">
      <el-drawer
          title="請選擇"
          :visible.sync="dialog"
          direction="btt"
          custom-class="demo-drawer"
          ref="drawer"
          size="40%"
          >
          <div class="demo-drawer__content">
            <el-radio-group v-model="selectedCoin">
                <el-radio class="brand" label="全品牌">全品牌</el-radio>
                <el-radio class="brand" label="朴庶韓國">朴庶韓國</el-radio>
                <el-radio class="brand" label="紫木槿">紫木槿</el-radio>
                <el-radio class="brand" label="給馬別邸">給馬別邸</el-radio>
                <el-radio class="brand" label="築間酸菜魚">築間酸菜魚</el-radio>
            </el-radio-group>
          </div>
      </el-drawer> 
    </div>

    <div class="white_drawer">
      <el-drawer
          title="會員條碼"
          :visible.sync="dialog_qrcode"
          direction="btt"
          custom-class="demo-drawer"
          ref="drawer"
          size="60%"
          >
          <div class="demo-drawer__content">
            <div class="barcode">
              <img src="@/assets/images/barcode.png">
            </div>
            <p class="member_code">-LM234GY6</p>
            <div class="qrcode">
              <img src="@/assets/images/qrcode.png">
            </div>
            <p class="memo">提醒您，以翻拍、截圖方式傳輸至其他設備，可能導致條碼無效。</p>
          </div>
      </el-drawer> 
    </div>

    <div class="white_drawer">
      <el-drawer
          title="VIP"
          :visible.sync="dialog_vip"
          direction="btt"
          custom-class="demo-drawer"
          ref="drawer"
          size="70%"
          >
          <div class="demo-drawer__content">
            <div class="card_img"><img src="@/assets/images/idx-slider-1.jpg"></div>
            <div class="card_content">
              <h2>優惠內容</h2>
              <p>凡至全台築間餐飲集團旗下品牌用餐，每消費100元可累積點數1點，以此類推，累積點數無上限，累積點數依照實際結帳金額為主。</p>
              
              <h2>加入條件</h2>
              <p>掃描各店 QRcode 可透過 LINE@ 加入綁定會員或直接下載Ocard App 加入</p>

              <h2>到期資訊</h2>
              <p>無</p>
            </div>
          </div>
      </el-drawer> 
    </div>
  </div>
</template>

<script>
export default {
  name: 'memberCardInfo',
  data() {
    return {
        activeTab: 0,
        dialog: false,
        dialog_qrcode: false,
        dialog_vip: false,
        selectedCoin: '全品牌',
        brand: {
            value: '全品牌' // 设置一个默认值
        },
        shop: [' '],
        initialCardHeight: 200,
        minCardHeight: 0, // 设置一个最小高度
        cardHeight: 200,
        cardOpacity: 1,
    };
  },
  components: {
    
  },
  watch: {
    selectedCoin(newValue) {
      this.brand.value = newValue;
      this.dialog = false; 
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.cardHeight = this.initialCardHeight;
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleClick() {
      this.dialog = true;
    },
    updateStart(value) {
      this.start = value;
      this.dialog = false;
    },
    setActiveTab(index) {
      this.activeTab = index;
    },
    goback(){
        return this.$router.go(-1);
    },
    handleScroll() {
      const scrollTop = window.scrollY;
      const newHeight = Math.max(this.minCardHeight, this.initialCardHeight - scrollTop);
      const newOpacity = newHeight / this.initialCardHeight;
      this.cardHeight = newHeight;
      this.cardOpacity = newOpacity;
    },
    goFAQ() {
      if (this.$route.path !== '/faq') {
        this.$router.push('/faq');
      }
    }
  }
}
</script>