<template>
    <div class="online white_drawer">
        <div class="close_bar">
            <div class="container">
                <button @click="goback" class="btn_close"></button>
            </div>
        </div>
        <div class="top_bar">
            <div class="container">
                <div class="shop_logo">
                    <router-link to="/online-store"><img src="@/assets/images/brand-12.jpeg"></router-link>
                </div>
                <button class="btn_language">繁體中文</button>
                <button class="btn_user" @click="dialog_menu = true"><img src="@/assets/images/idx-slider-3.jpg"></button>
            </div>
        </div>
        <div class="top_area">
            <div class="shop_img" :style="{ 'background-image': 'url(' + require('@/assets/images/idx-slider-13.jpg') + ')' }"></div>
            <div class="shop_info">
                <div class="logo"><img src="@/assets/images/brand-12.jpeg"></div>
                <h1 class="name">鮮芋仙</h1>
                <p class="branch">內湖家樂福店</p>
                <div class="btns">
                    <button @click="openInfo =! openInfo">店家資訊</button>
                    <button @click="dialog_group = true">揪團訂購</button>
                </div>
                <div class="info" v-if="openInfo">
                    <ul>
                        <li class="locate">台北市松山區松基里民生東路三段130巷14號</li>
                        <li class="phone">02-27120921</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tabs">
            <div class="container">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane name="first">
                        <span slot="label"><i class="el-icon-school"></i><br>外送上門</span>
                        <div class="inputs">
                            <ul>
                                <li>
                                    <div class="img"><img src="@/assets/images/icon-clock-black.svg"></div>
                                    <div class="text">
                                        <label>訂單時間</label>
                                        <el-select v-model="value" placeholder="請選擇">
                                            <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </li>
                            </ul>
                            <ul v-if="value === '2'">
                                <li>
                                    <div class="img"><img src="@/assets/images/icon-calendar.svg"></div>
                                    <div class="text">
                                        <label>日期</label>
                                        <el-select v-model="date" placeholder="請選擇">
                                            <el-option
                                            v-for="item in date_options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </li>
                                <li class="paddingLeft">
                                    <div class="text">
                                        <label>時間</label>
                                        <el-select v-model="time" placeholder="請選擇">
                                            <el-option
                                            v-for="item in time_options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div class="img"><img src="@/assets/images/icon-home.svg"></div>
                                    <div class="text">
                                        <input type="text" placeholder="縣市接到地址（不含樓層）">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="second">
                        <span slot="label"><i class="el-icon-goods"></i><br>外帶自取</span>
                        <div class="inputs">
                            <ul>
                                <li>
                                    <div class="img"><img src="@/assets/images/icon-clock-black.svg"></div>
                                    <div class="text">
                                        <label>訂單時間</label>
                                        <el-select v-model="value" placeholder="請選擇">
                                            <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </li>
                            </ul>
                            <ul v-if="value === '2'">
                                <li>
                                    <div class="img"><img src="@/assets/images/icon-calendar.svg"></div>
                                    <div class="text">
                                        <label>日期</label>
                                        <el-select v-model="date" placeholder="請選擇">
                                            <el-option
                                            v-for="item in date_options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </li>
                                <li class="paddingLeft">
                                    <div class="text">
                                        <label>時間</label>
                                        <el-select v-model="time" placeholder="請選擇">
                                            <el-option
                                            v-for="item in time_options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div class="container">
            <div class="announce">
                <h3><img src="@/assets/images/icon-info.svg"> 公告事項</h3>
                <p>大量訂單請提前一天預訂...</p>
                <button @click="dialog = true">展開閱讀</button>
            </div>
        </div>
        <div class="container">
            <div class="rule">
                運送距離上限為8公里。<br>
                運送費用規則：<br>
                • 0km ~1km，運費＄0，最低消費＄400<br>
                • 1km ~ 3km，運費$0，最低消費＄500<br>
                • 3km ~5km，運費＄0，最低消費＄700<br>
                • 5km ~6km，運費$0，最低消費＄800<br>
                • 6km ~8km，運費＄0，最低消費＄1,000
            </div>
        </div>
        <div class="search">
            <div class="container">
                <input type="text" placeholder="搜尋商品">
                <div class="btns">
                    <button :class="{ active: isLineView }" @click="setView('line')"><img src="@/assets/images/icon-line.svg"></button>
                    <button :class="{ active: isBlockView }" @click="setView('block')"><img src="@/assets/images/icon-block.svg"></button>
                </div>
            </div>
        </div> <!--end of search-->

        <div class="store_items">
            <div class="container">
                <h2>夏日漫波系列</h2>
                <ul :class="listClass">
                    <li>
                        <div class="img">
                            <img src="@/assets/images/store_img_1.png">
                            <button class="btn_add" @click="dialog_items = true"><i class="el-icon-plus"></i></button>
                        </div>
                        <div class="text">
                            <h3>芝麻搖擺</h3>
                            <p>奶茶冰沙＋芋圓＋紅茶凍＋珍珠＋芝麻綿綿冰＋ 煉乳</p>
                            <span class="price">$100</span>
                        </div>
                        <button class="btn_add line" @click="dialog_items = true"><i class="el-icon-plus"></i></button>
                    </li>
                    <li>
                        <div class="img">
                            <img src="@/assets/images/store_img_2.png">
                            <button class="btn_add" @click="dialog_items = true"><i class="el-icon-plus"></i></button>
                        </div>
                        <div class="text">
                            <h3>島灰漫波</h3>
                            <p>奶茶冰沙＋芋圓＋豆花＋珍珠＋芝麻綿綿冰＋ 煉乳</p>
                            <span class="price">$100</span>
                        </div>
                        <button class="btn_add line" @click="dialog_items = true"><i class="el-icon-plus"></i></button>
                    </li>
                    <li>
                        <div class="img">
                            <img src="@/assets/images/store_img_1.png">
                            <button class="btn_add" @click="dialog_items = true"><i class="el-icon-plus"></i></button>
                        </div>
                        <div class="text">
                            <h3>芝麻搖擺</h3>
                            <p>奶茶冰沙＋芋圓＋紅茶凍＋珍珠＋芝麻綿綿冰＋ 煉乳</p>
                            <span class="price">$100</span>
                        </div>
                        <button class="btn_add line" @click="dialog_items = true"><i class="el-icon-plus"></i></button>
                    </li>
                    <li>
                        <div class="img">
                            <img src="@/assets/images/store_img_2.png">
                            <button class="btn_add" @click="dialog_items = true"><i class="el-icon-plus"></i></button>
                        </div>
                        <div class="text">
                            <h3>島灰漫波</h3>
                            <p>奶茶冰沙＋芋圓＋豆花＋珍珠＋芝麻綿綿冰＋ 煉乳</p>
                            <span class="price">$100</span>
                        </div>
                        <button class="btn_add line" @click="dialog_items = true"><i class="el-icon-plus"></i></button>
                    </li>
                </ul>
            </div>
        </div> <!--end of store_items-->

        <!--線上點公告事項-->
        <el-drawer
            title="線上點公告事項"
            :visible.sync="dialog"
            direction="btt"
            custom-class="text_left"
            ref="drawer"
            size="40%"
            >
            <div class="demo-drawer__content">
                <div class="announce_content">
                    <p>大量訂單請提前一天預訂<br>當天訂單會依現場狀況接單（外送不用加購物袋）<br>造成不便敬請見諒</p>
                    <p>運送距離上限8公里，運送費用規則：<br>
                        OKM-1KM，運費＄0，最低消費＄400<br>
                        1.1KM-3KM，運費＄0，最低消費＄500<br>
                        3.1KM-5KM，運費＄0，最低消費＄700<br>
                        5.1KM-6KM，運費$0，最低消費＄800<br>
                        6.1KM-8KM，運費$0，最低消費＄1000
                    </p>
                </div>
            </div>
        </el-drawer>

        <!--商品資訊-->
        <el-drawer
            :visible.sync="dialog_items"
            direction="btt"
            :with-header="false"
            custom-class="store_items"
            ref="drawer"
            size="95%"
            :show-close="true"
            >
            <div class="demo-drawer__content">
                <div class="img">
                    <button class="btn_close" @click="dialog_items = false"><img src="@/assets/images/icon-close-gray.svg"></button>
                    <img src="@/assets/images/store_img_1.png">
                </div>
                <h1>芝麻搖擺</h1>
                <span class="price">$100</span>
                <p class="description">奶茶冰沙＋芋圓＋紅茶凍＋珍珠＋芝麻綿綿冰＋ 煉乳</p>
                <h2>溫度</h2>
                <span class="sub_title">必選項目，選擇1項</span>
                <el-radio-group v-model="form.radio">
                    <el-radio label="冰">
                        <span class="items">冰</span>
                        <span class="price">$0</span>
                    </el-radio>
                    <el-radio label="熱">
                        <span class="items">熱</span>
                        <span class="price">$0</span>
                    </el-radio>
                </el-radio-group>
            </div>
            <div class="demo-drawer__footer">
                <button disabled><img src="@/assets/images/icon-minus.svg"></button>
                <span class="amount">1</span>
                <button><img src="@/assets/images/icon-plus.svg"></button>
                <button class="btn_add">加入購物車
                    <span class="small_total">小計<br>$75</span>
                </button>
            </div>
        </el-drawer>

        <!--揪團訂購-->
        <el-drawer
            title="揪團訂購"
            :visible.sync="dialog_group"
            direction="btt"
            custom-class="group"
            ref="drawer"
            size="40%"
            >
            <div class="demo-drawer__content">
                <div class="group_img">
                    <div class="circle"><img src="@/assets/images/add-group.svg"></div>
                </div>
                <div class="group_content">
                    <p>發起揪團訂購，讓親朋好友一起加入訂單！</p>
                </div>
                <button class="btn_start" @click="openGroup">開始揪團</button>
            </div>
        </el-drawer>

        <el-drawer
            title="揪團訂購"
            :visible.sync="dialog_group2"
            direction="btt"
            custom-class="group"
            ref="drawer"
            size="50%"
            >
            <div class="demo-drawer__content">
                <div class="group_content">
                    <p>分享連結，讓親朋好友一起加入訂單！</p>
                    <div class="copy_area">
                        加入 OOO 的 Ocard 線上點訂單！https://order.ocard.co/vegebirdpoke/w2NA4w?group=Y9V415E
                    </div>
                    <ul class="btns">
                        <li>
                            <button><img src="@/assets/images/icon-share.svg"></button><br>分享揪團
                        </li>
                        <li>
                            <button><img src="@/assets/images/icon-link.svg"></button><br>複製
                        </li>
                    </ul>
                </div>
            </div>
            <div class="demo-drawer__footer">
                <button class="btn_start" @click="dialog_group2 = false">取消揪團</button>
                <button class="btn_start" @click="dialog_group2 = false">開始選購</button>
            </div>
        </el-drawer>

        <!--選單-->
        <OnlineMenu :dialog_menu.sync="dialog_menu" @go-faq="goFAQ" />
    </div>
</template>

<script>
import OnlineMenu from '@/components/OnlineMenu.vue'
export default {
    data() {
      return {
        dialog_menu: false,
        dialog_group: false,
        dialog_group2: false,
        openInfo: false,
        dialog: false,
        dialog_items: false,
        activeName: 'first',
        options: [{
          value: '1',
          label: '即時訂單'
        }, {
          value: '2',
          label: '預約訂單'
        }],
        value: '1', // 初始值设为即时订单
        view: 'block',
        form: {
            radio: '',
        },
        date: '6/3 週一',
        date_options: [{
            value: '1',
            label: '6/3 週一'
        },
        {
            value: '2',
            label: '6/4 週二'
        },
        {
            value: '3',
            label: '6/5 週三'
        },
        {
            value: '4',
            label: '6/6 週四'
        },
        {
            value: '5',
            label: '6/7 週五'
        }],
        time: '11:30 ~ 12:00',
        time_options: [{
            value: '1',
            label: '11:30 ~ 12:00'
        },
        {
            value: '2',
            label: '12:30 ~ 13:00'
        },
        {
            value: '3',
            label: '13:30 ~ 14:00'
        }]
      }
    },
    components: {
        OnlineMenu
    },
    computed: {
        isLineView() {
            return this.view === 'line';
        },
        isBlockView() {
            return this.view === 'block';
        },
        listClass() {
            return {
                line: this.isLineView,
                block: this.isBlockView,
            };
        },
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        goback(){
            return this.$router.go(-1);
        },
        setView(viewType) {
            this.view = viewType;
        },
        openGroup(){
            this.dialog_group = false;
            this.dialog_group2 = true;
        },
        goFAQ() {
            if (this.$route.path !== '/faq') {
                this.$router.push('/faq');
            }
        }
    }
}
</script>

<style>

</style>