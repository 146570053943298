<template>
  <div class="main no-padding">
    <div class="dark">
        <div class="top_area">
            <div class="container">
                <div class="title">授權管理
                    <button @click="goback()" class="btn_close"></button>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="comment center">
                    <p>無論您是否接受授權，事後您都可以在「設定」App中再次更改設定</p>
                </div>
                <div class="auth">
                    <ul>
                        <li><img src="@/assets/images/icon-check.svg"> 發送通知</li>
                        <li><img src="@/assets/images/icon-check.svg"> 取用您的相機</li>
                        <li><img src="@/assets/images/icon-check.svg"> 取用您的地理位置</li>
                    </ul>
                </div>
            </div> <!--end of container-->
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'auth',
    data() {
        return {
        };
    },
    components: {

    },
    methods: {
        submit(){
            return this.$router.go(-1);
        },
        goback(){
            return this.$router.go(-1);
        }
    }
}
</script>