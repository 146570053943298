<template>
  <div class="member_page pointInfo" :style="{ 'background-image': 'url(' + require('@/assets/images/idx-slider-8.jpg') + ')' }">
    <div class="cover"></div>
    <div class="top_area">
      <div class="container">
        <div class="top_bar">
          <div class="title">集點獎勵</div>
          <button @click="goback()" class="btn_back"></button>
        </div> <!--end of top_bar-->
      </div> <!--end of container-->
    </div> <!--end of top_area-->
    <div class="bottom_area">
      <div class="container">
        <div class="point_img">
            <div class="title">築間幸福鍋物 -【3點】兌換券</div>
            <div class="img"><img src="@/assets/images/idx-slider-8.jpg"></div>
            <div class="point"><span class="highlight">3</span>點</div>
        </div>
        <div class="point_content">
            <h1>優惠內容</h1>
            <p>
                兌換品項：<br>金縷腐皮8片<br><br>
                注意事項：<br>
                1. 點數【每份套餐限兌換一次】，不可重複兌換<br>
                2. 點數效期於用餐後隔日開始計算【63天】，逾期點數將自動移除<br>
                3. 當次消費累積點數，限【下次消費】兌換使用。<br>
                4. 使用點數，需於點餐時兌換完畢，點餐後恕不受理。<br>
                5. 點數兌換時請將手機提供給工作人員進行核銷。<br>
                6. 點數限本人使用，不得與其他優惠合併使用，亦無法轉移或贈送。<br>
                7. 其他未盡事宜以店內公告為主，本公司保有調整活動辦法、優惠內容及終止之權利。
            </p>
        </div>
      </div> <!--end of container-->
    </div> <!-- end of bottom_area-->
  </div>
</template>

<script>
export default {
  name: 'memberCardInfo',
  data() {
    return {
    };
  },
  components: {
    
  },
  methods: {
      goback(){
        return this.$router.go(-1);
      }
  }
}
</script>