<template>
    <div class="map">
        <div class="container">
            <div class="mapCover">
                <GmapMap
                :center="center"
                :zoom="14"
                :options="mapOptions"
                >
                <GmapMarker
                    v-for="(place, index) in places"
                    :key="index"
                    :position="place.position"
                    :icon="place.icon"
                    @click="center = place.position"
                >
                    <!-- <GmapInfoWindow :options="infoOptions" :position="place.position">
                    <div>
                        <h2>{{ place.name }}</h2>
                        <p>{{ place.address }}</p>
                    </div>
                    </GmapInfoWindow> -->
                </GmapMarker>
                </GmapMap>
            </div>
            <div class="top_area">
                <button @click="goback" class="btn_back"></button></router-link>
                <button @click="getCurrentLocation" class="btn_locate_now"></button>
            </div> <!--end of top_area-->
            <div class="content">
                <button class="btn_filter" @click="dialog = true"><img src="@/assets/images/icon-filter.svg"> 篩選</button>
                <div class="shop_card">
                    <VueSlickCarousel v-bind="settings">
                        <div class="card" v-for="(item, index) in recommend_items" :key="index">
                            <router-link to="/store">
                            <div class="img"><img :src="item.image" alt="carousel-item"></div>
                            <div class="shop_info">
                            <div class="tag">{{ item.tag }}</div>
                            <div class="name">{{ item.name }}</div>
                            <div class="info">
                                <span class="star">{{ item.star }}</span>
                                <span class="location">{{ item.location }}</span>
                                <span class="pointback">{{ item.pointback }}</span>
                            </div>
                            </div>
                            </router-link>
                        </div>
                    </VueSlickCarousel>
                </div> <!--end of shop_card-->
            </div> <!--end of content-->

            <el-drawer
                title="篩選"
                :visible.sync="dialog"
                direction="btt"
                custom-class="demo-drawer"
                ref="drawer"
                size="70%"
                >
                <div class="demo-drawer__content">
                    <span class="title"><img src="@/assets/images/icon-click.svg">線上外帶外送</span>
                    <el-checkbox-group v-model="checkboxGroup1" size="medium">
                        <el-checkbox-button v-for="way in ways" :label="way" :key="way">{{way}}</el-checkbox-button>
                    </el-checkbox-group>
                    <span class="title"><img src="@/assets/images/icon-map-gray.svg">距離</span>
                    <el-checkbox-group v-model="checkboxGroup2" size="medium">
                        <el-checkbox-button v-for="distance in km" :label="distance" :key="distance">{{distance}}</el-checkbox-button>
                    </el-checkbox-group>
                    <span class="title"><img src="@/assets/images/icon-click.svg">快選</span>
                    <el-checkbox-group v-model="checkboxGroup3" size="medium">
                        <el-checkbox-button v-for="quick in select" :label="quick" :key="quick">{{quick}}</el-checkbox-button>
                    </el-checkbox-group>
                    <span class="title"><img src="@/assets/images/icon-type.svg">類型</span>
                    <el-checkbox-group v-model="checkboxGroup4" size="medium">
                        <el-checkbox-button v-for="type in types" :label="type" :key="type">{{type}}</el-checkbox-button>
                    </el-checkbox-group>
                    <span class="title"><img src="@/assets/images/icon-mrt.svg">捷運商圈</span>
                    <el-checkbox-group v-model="checkboxGroup5" size="medium">
                        <el-checkbox-button v-for="mrt in mrts" :label="mrt" :key="mrt">{{mrt}}</el-checkbox-button>
                    </el-checkbox-group>
                    <span class="title"><img src="@/assets/images/icon-dao.svg">DAOCoin</span>
                    <el-checkbox-group v-model="checkboxGroup6" size="medium">
                        <el-checkbox-button v-for="coin in coins" :label="coin" :key="coin">{{coin}}</el-checkbox-button>
                    </el-checkbox-group>
                    <div class="demo-drawer__footer">
                        <el-button class="btn_clear" @click="cancelForm">清空</el-button>
                        <el-button @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '篩選中 ...' : '套用' }}</el-button>
                    </div>
                </div>
            </el-drawer> 
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
const wayOptions = ['外帶', '外送', '即時', '預約'];
const distanceOptions = ['<2km', '<5km', '<8km', '<10km'];
const quickOptions = ['我的口袋名單', '線上點 Ocard Rewards', '到店 Ocard Rewards', '營業中', '會員招募中', '嚐鮮優惠券'];
const typeOptions = ['餐飲', '住宿', '娛樂', '生活', '藥妝', '零售', '美容'];
const mrtOptions = ['台北捷運'];
const coinOptions = ['DAO賺取', 'DAO折抵', 'DAO兌換'];
export default {
    name: 'SearchMap',
    data() {
        return {
            //filter
            input: '#美食外帶外送專區',
            filter_items: '2',
            dialog: false,
            loading: false,
            checkboxGroup1: ['外帶', '外送'],
            ways: wayOptions,
            checkboxGroup2: [],
            km: distanceOptions,
            checkboxGroup3: [],
            select: quickOptions,
            checkboxGroup4: [],
            types: typeOptions,
            checkboxGroup5: [],
            mrts: mrtOptions,
            checkboxGroup6: [],
            coins: coinOptions,
            timer: null,
            //map
            center: { lat: 25.083677, lng: 121.575643 }, // 內湖站附近
            places: [
                {
                    name: '卡拉拉涮涮鍋',
                    address: '台北市內湖區內湖路一段737號',
                    position: { lat: 25.083677, lng: 121.575643 },
                    icon: require('@/assets/images/custom-marker.png'), // 自定义标记图标
                },
            ],
            mapOptions: {
                styles: [
                {
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#242f3e"
                    }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#746855"
                    }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                    {
                        "color": "#242f3e"
                    }
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#d59563"
                    }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#d59563"
                    }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#263c3f"
                    }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#6b9a76"
                    }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#38414e"
                    }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.stroke",
                    "stylers": [
                    {
                        "color": "#212a37"
                    }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#9ca5b3"
                    }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#746855"
                    }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                    {
                        "color": "#1f2835"
                    }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#f3d19c"
                    }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#2f3948"
                    }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#d59563"
                    }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#17263c"
                    }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#515c6d"
                    }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                    {
                        "color": "#17263c"
                    }
                    ]
                }
                ],
            },
            infoOptions: {
                pixelOffset: {
                width: 0,
                height: -35,
                },
            },
            mapTypeControl: false,
            zoomControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            settings: {
                focusOnSelect: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                touchThreshold: 1,
                autoplay: false,
            },
            recommend_items: [
                { 
                image: require('@/assets/images/idx-slider-1.jpg'),
                tag: '#手作甜點 #微奢甜點季 #日式洋果子',
                name: '海海洋菓',
                star: '4.3 ★',
                location: '4.5km',
                pointback: '到店1.5% 回饋'
                },
                { 
                image: require('@/assets/images/idx-slider-2.jpg'),
                tag: '#手作甜點 #微奢甜點季 #日式洋果子',
                name: '海海洋菓',
                star: '4.3 ★',
                location: '4.5km',
                pointback: '到店1.5% 回饋'
                },
                { 
                image: require('@/assets/images/idx-slider-3.jpg'),
                tag: '#手作甜點 #微奢甜點季 #日式洋果子',
                name: '海海洋菓',
                star: '4.3 ★',
                location: '4.5km',
                pointback: '到店1.5% 回饋'
                },
                { 
                image: require('@/assets/images/idx-slider-4.jpg'),
                tag: '#手作甜點 #微奢甜點季 #日式洋果子',
                name: '海海洋菓',
                star: '4.3 ★',
                location: '4.5km',
                pointback: '到店1.5% 回饋'
                },
                // Add more carousel items as needed
            ],
        }
    },
    components: {
        VueSlickCarousel
    },
    methods: {
        goback(){
            return this.$router.go(-1);
        },
        getCurrentLocation() {
            // 獲取用戶當前位置
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                position => {
                    const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                    };
                    // 將用戶當前位置顯示在地圖上
                    this.map.setCenter(pos);
                    if (this.marker) {
                    this.marker.setPosition(pos);
                    } else {
                    this.marker = new google.maps.Marker({
                        position: pos,
                        map: this.map
                    });
                    }
                },
                () => {
                    console.error('無法獲取地理位置。');
                }
                );
            } else {
                console.error('你的瀏覽器不支持地理位置功能。');
            }
        },
        cancelForm() {
            this.loading = false;
            this.dialog = false;
            clearTimeout(this.timer);
        },
        updateSettingsForScreenWidth() {
            if (window.innerWidth > 980) {
                this.settings.slidesToShow = 2;
                this.settings.touchThreshold = 4;
                this.settings.slidesPerRow = 1;
            } else {
                this.settings.slidesToShow = 2;
                this.settings.touchThreshold = 3;
                this.settings.slidesToShow = 1;
                this.settings.touchThreshold = 2;
            }
        },
    },
    mounted() {
        this.updateSettingsForScreenWidth();
        window.addEventListener('resize', this.updateSettingsForScreenWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateSettingsForScreenWidth);
    },
};
</script>