<template>
    <div class="mission page">
        <div class="top_area top_bar">
            <div class="container">
                <div class="page_title">
                    <button @click="goback()" class="btn_back"></button>
                    下午茶達人
                </div>
            </div>
        </div> <!--end of top_area-->
        <div class="content">
            <div class="container">
                <div class="mission_image">
                    <div class="box">
                        <div class="img">
                            <img src="@/assets/images/img-tea.jpg">
                        </div>
                        <div class="point_list">
                            <VueSlickCarousel v-bind="settings">
                                <div><h3>1</h3></div>
                                <div><h3>2</h3></div>
                                <div><h3>3</h3></div>
                                <div><h3>4</h3></div>
                                <div><h3>5</h3></div>
                                <div><h3>6</h3></div>
                                <div><h3>7</h3></div>
                                <div><h3>8</h3></div>
                                <div><h3>9</h3></div>
                                <div><h3>10</h3></div>
                            </VueSlickCarousel>
                        </div>
                        <div class="btn_view">
                            <router-link to="/search-result"><button>查看店家</button></router-link>
                        </div>
                    </div> <!--end of box-->
                </div> <!--end of mission_image-->
                <div class="mission_info">
                    <h1>任務說明</h1>
                    <p>到5間/10間/15間「下午茶」類型的 Ocard 特約店家消費賺取回饋，即可解鎖任務、獲得獎勵。</p>
                    <h1>任務獎勵</h1>
                    <p>最多享 240 DAOcoin<br>5間-40 DAOcoin<br>10間-80 DAOcoin<br>15 間-120 DAOcoin</p>
                    <h1>注意事項</h1>
                    <p>•此任務為一次性任務，完成任務後不可再次進行。<br>・可解鎖此任務的特約店家以任務內「査看店家」連結之店家名單為主<br>．此任務需到不同間下午茶類型的特約店家消費累積至少1點/1里才能納入計算，若到同一間店消費2次仍視為踩點1間店家<br>•到「DAOcoin 特約店家」消費，報手機號碼即可賺取3%消費金額的回饋<br>・成功賺取回饋後，可至「回饋獎勵明細」査看消費紀錄及回饋獎勵<br>•若消費後未成功賺取回饋，請來信聯絡客服專員，並需提供消費店家名稱、消費發票、手機號碼等資訊，以利專員進行協助及查詢。<br>客服信箱：service@ocard.co<br>・DAOcoin 保有隨時更改或終止本任務之權利，如有變動恕不另行通知<br>•如有任何爭議，DAOcoin 保留最終決定權</p>
                </div> <!--end of mission_info-->
            </div>
        </div> <!--end of content-->
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// @ is an alias to /src
export default {
    name: 'MissionContent',
    data() {
        return {
            input: '',
            settings: {
                dots: true,
                focusOnSelect: true,
                infinite: true,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 5,
                touchThreshold: 5
            },
        };
    },
    components: {
        VueSlickCarousel
    },
    methods: {
        goback(){
            return this.$router.go(-1);
        }
    }

}
</script>