<template>
    <div class="main">
        <div class="exhange page">
            <div class="top_bar">
                <div class="container">
                    <button @click="goback()" class="btn_back"></button>
                    全家便利商店
                </div>
            </div> <!--end of top_bar-->
            <div class="bottom_bar">
                <div class="container">
                    <div class="platform_name">
                        <div class="logo"><img src="@/assets/images/transfer-1.png"></div>
                        <div class="text">全家便利商店致力於提供創新的產品與服務，讓您的生活更便利、更有意思！Fa 點可在「全家」消費折抵現金、兌換咖啡、民生用品等⋯</div>
                    </div>
                    <h1>使用 DAOcoin</h1>
                    <router-link to="/platform">
                    <div class="platform_box">
                        <div class="left">將 DAOcoin 轉換成 Fa點</div>
                        <div class="right">
                            <div class="logoFrom"><img src="@/assets/images/exchange_logo.svg"></div>
                            <div class="arrow"><img src="@/assets/images/icon-next.svg"></div>
                            <div class="logoTo"><img src="@/assets/images/transfer-1.png"></div>
                        </div>
                    </div>
                    </router-link>
                    <h1>賺取 DAOcoin</h1>
                    <div class="platform_box">
                        <div class="left">將 Fa 點轉換成 DAOcoin</div>
                        <div class="right">
                            <div class="logoFrom"><img src="@/assets/images/transfer-1.png"></div>
                            <div class="arrow"><img src="@/assets/images/icon-next.svg"></div>
                            <div class="logoTo"><img src="@/assets/images/exchange_logo.svg"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!--end of exhange-->
    </div> <!--end of main-->
</template>
<script>
export default {
    name: 'exchangeStore',
    data() {
        return {
            
        };
    },
    components: {

    },
    mounted() {
        
    },
    methods: {
        goback(){
            return this.$router.go(-1);
        }
    },
}
</script>