<template>
    <div class="mission page">
        <div class="top_area top_bar">
            <div class="container">
                <div class="page_title">
                    <button @click="goback()" class="btn_back"></button>
                    任務專區
                </div>
            </div>
        </div> <!--end of top_area-->
        <div class="content">
            <div class="container">
                <div class="hot list_small">
                    <h1>最HOT任務</h1>
                    <div class="top_card">
                        <ul>
                            <li>
                                <router-link to="/mission-content">
                                <div class="img"><img src="@/assets/images/img-tea.jpg"></div>
                                <div class="text">下午茶達人
                                    <span class="sub">最多享 240 Ocoin</span>
                                </div>
                                </router-link>
                            </li>
                            <li>
                                <div class="img"><img src="@/assets/images/img-brunch.jpg"></div>
                                <div class="text">早午餐達人
                                    <span class="sub">最多享 240 Ocoin</span>
                                </div>
                            </li>
                            <li>
                                <div class="img"><img src="@/assets/images/img-drink.jpg"></div>
                                <div class="text">手搖達人
                                    <span class="sub">最多享 150 Ocoin</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <h1>新手任務</h1>
                    <div class="top_card">
                        <ul class="border">
                            <li>
                                <div class="title">賺取 DAOCoin 回饋</div>
                                <div class="point">10 <img src="@/assets/images/coin-doucoing.svg"></div>
                            </li>
                            <li>
                                <div class="title">使用 DAOCoin 回饋</div>
                                <div class="point">5 <img src="@/assets/images/coin-doucoing.svg"></div>
                            </li>
                            <li>
                                <div class="title">品牌點數轉 DAOCoin</div>
                                <div class="point">5 <img src="@/assets/images/coin-doucoing.svg"></div>
                            </li>
                            <li>
                                <div class="title">搜尋店家</div>
                                <div class="point">已完成</div>
                            </li>
                            <li>
                                <div class="title">收藏店家</div>
                                <div class="point">已完成</div>
                            </li>
                            <li>
                                <div class="title">連結回饋帳戶</div>
                                <div class="point">5 <img src="@/assets/images/coin-doucoing.svg"></div>
                            </li>
                        </ul>
                    </div>
                    <h1>達人系列 <button class="btn_more">查看全部</button></h1>
                    <div class="top_card">
                        <ul class="border">
                            <li>
                                <div class="logo"><img src="@/assets/images/logo.svg"></div>
                                <div class="title">下午茶達人</div>
                                <div class="sub">最多享 240 Ocoin</div>
                            </li>
                            <li>
                                <div class="logo"><img src="@/assets/images/logo.svg"></div>
                                <div class="title">早午餐達人</div>
                                <div class="sub">最多享 240 Ocoin</div>
                            </li>
                            <li>
                                <div class="logo"><img src="@/assets/images/logo.svg"></div>
                                <div class="title">手搖達人</div>
                                <div class="sub">最多享 150 Ocoin</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> <!--end of content-->
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'Mission',
    data() {
        return {
            input: ''
        };
    },
    components: {
        
    },
    methods: {
        goback(){
            return this.$router.go(-1);
        }
    }
}
</script>