<template>
    <div class="main">
        <div class="exhange page">
            <div class="top_bar" :style="{ 'background-image': 'url(' + require('@/assets/images/transfer-1.png') + ')' }">
                <button @click="goback()" class="btn_back"></button>
                兌換內容
            </div> <!--end of top_bar-->
            <div class="bottom_bar">
                <div class="exchange_img">
                    <img src="@/assets/images/transfer-1-coupon.png">
                </div> <!--end of exchange_img-->
                <div class="exchange_title">
                    Fa 點2,000點
                    <div class="point">10 <img src="@/assets/images/coin-doucoing.svg"></div>
                </div> <!--end of exchange_title-->
                <div class="contnet">
                    <h1>內容</h1>
                    <p>Fa 點2,000點</p>
                    <h1>注意事項</h1>
                    <p>
                        ・活動兌換期間：2024/04/01~2024/06/30<br>
                        ・序號歸戶期間：2024/04/01~2024/06/30<br>
                        ・點數使用期限：2024/04/01~2025/03/31<br><br>
                        1.需先成為全家便利商店會員方能使用全家 Fa 點，非會員請先加入全家便利商店會員。<br>
                        2.兌換完成後，請於禮物盒內商品詳情，長按複製上方「商品序號」。<br>
                        3.該點數序號必須於指定期限內完成兌換，逾期兌換無效，且不得以任何理由要求補發點數序號或更換等值商品。<br>
                        4.會員可透過全家便利商店 APP 輸入序號。每一PIN碼僅能輸入一次，無法重複輸入。<br>
                        5.輸入序號碼後，點數將自動匯入用戶點數池。<br>
                        6.本序號請妥善保管，若遺失、毀損或保存不當而造成序號有誤，恕不補發。<br>
                        7.本序號為不記名，任何人持本序號皆可兌換點數，請自行妥善保管。<br>
                        8.本序號限兌換乙次，一經使用，一律不接受退貨。<br>
                    </p>
                </div> <!--end of contnet-->
                <div class="btns fix_bottom">
                    <button @click="submit()" class="btn_submit">進行兌換</button>
                </div>
            </div> <!--end of bottom_bar-->
        </div> <!--end of exhange-->
    </div> <!--end of main-->
</template>
<script>
export default {
    name: 'exchangeContent',
    data() {
        return {
            
        };
    },
    components: {

    },
    mounted() {
        
    },
    methods: {
        goback(){
            return this.$router.go(-1);
        },
        submit(){
            return this.$router.go(-1);
        },
    },
}
</script>