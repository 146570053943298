<template>
    <div class="search">
        <div class="top_area">
            <div class="container">
                <button @click="goback" class="btn_back"></button>
                <!-- <input class="input_search" placeholder="請輸入關鍵字"> -->
                <el-input class="input_search" placeholder="請輸入關鍵字" v-model="input" clearable></el-input>
                <button @click="gotomap" class="btn_map"></button>
                <p class="tips">按右方地圖鈕探索或輸入店名、捷運站、餐廳類型等<br>關鍵字來搜尋！例：「中山區 甜點」</p>
            </div>
        </div> <!--end of top_input-->
        <div class="content">
            <div class="container">
                <h1>推薦主題</h1>
                <ul class="tags">
                    <li>
                        <router-link to="/search-result">美食外帶外送專區</router-link>
                    </li>
                    <li>
                        <router-link to="/search-coupon">嚐鮮券</router-link>
                    </li>
                    <li>
                        <router-link to="/search-result">DaoCoin特約回饋店家</router-link>
                    </li>
                    <li>
                        <router-link to="/search-result">會員招募中</router-link>
                    </li>
                    <li>
                        <router-link to="/search-result">附近店家</router-link>
                    </li>
                </ul>
                <h1>最近搜尋</h1>
                <ul class="history">
                    <li>麵
                        <button class="btn_delete"></button>
                    </li>
                    <li>飯
                        <button class="btn_delete"></button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'Search',
    data() {
        return {
            input: ''
        };
    },
    components: {
        
    },
    methods: {
        goback(){
            return this.$router.go(-1);
        },
        gotomap(){
            return this.$router.push('search-map')
        }
    }

}
</script>