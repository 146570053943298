<template>
    <div class="main">
        <div class="exhange page">
            <div class="top_bar large" :class="{ scrolled: isScrolled }" :style="{ 'background-image': 'url(' + require('@/assets/images/transfer-1.png') + ')' }">
                <div class="container">
                    <button @click="goback()" class="btn_back"></button>
                    <div class="logo" :style="{ height: logoHeight }">
                        <img src="@/assets/images/transfer-1.png">
                    </div>
                </div>
            </div> <!--end of top_bar-->
            <div class="bottom_bar large" :class="{ scrolled: isScrolled }">
                <div class="container">
                    <div class="store_name">
                        全家便利商店<br>
                        <span class="word_s">Fa點</span>
                    </div>
                    <ul class="coupon">
                        <li>
                            <router-link to="platform-content">
                                <div class="img"><img src="@/assets/images/transfer-1-coupon.png"></div>
                            </router-link>
                            <div class="text">Fa 點 2,000點
                                <div class="point">10 <img src="@/assets/images/coin-doucoing.svg"></div>
                            </div>
                        </li>
                        <li>
                            <router-link to="platform-content">
                                <div class="img"><img src="@/assets/images/transfer-1-coupon.png"></div>
                            </router-link>
                            <div class="text">Fa 點 2,000點
                                <div class="point">10 <img src="@/assets/images/coin-doucoing.svg"></div>
                            </div>
                        </li>
                        <li>
                            <router-link to="platform-content">
                                <div class="img"><img src="@/assets/images/transfer-1-coupon.png"></div>
                            </router-link>
                            <div class="text">Fa 點 2,000點
                                <div class="point">10 <img src="@/assets/images/coin-doucoing.svg"></div>
                            </div>
                        </li>
                        <li>
                            <router-link to="platform-content">
                                <div class="img"><img src="@/assets/images/transfer-1-coupon.png"></div>
                            </router-link>
                            <div class="text">Fa 點 2,000點
                                <div class="point">10 <img src="@/assets/images/coin-doucoing.svg"></div>
                            </div>
                        </li>
                        <li>
                            <router-link to="platform-content">
                                <div class="img"><img src="@/assets/images/transfer-1-coupon.png"></div>
                            </router-link>
                            <div class="text">Fa 點 2,000點
                                <div class="point">10 <img src="@/assets/images/coin-doucoing.svg"></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div> <!--end of bottom_bar-->
        </div> <!--end of exhange-->
    </div> <!--end of main-->
</template>
<script>
export default {
    name: 'exchangeStore',
    data() {
        return {
            isScrolled: false, // 是否向下滚动
            logoHeight: '240px', // 初始logo高度
            bottomPadding: '250px', // 初始padding-top
        };
    },
    components: {

    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll); // 监听页面滚动事件
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll); // 移除滚动事件监听器
    },
    methods: {
        handleScroll() {
            // 获取页面滚动距离
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            // 根据页面滚动距离动态改变样式
            this.isScrolled = scrollTop > 0;
            // 计算动态高度
            this.logoHeight = Math.max(240 - scrollTop, 60) + 'px'; // 最小高度为60px
            // 计算动态padding-top
            this.bottomPadding = Math.max(250 - scrollTop, 60) + 'px'; // 最小padding-top为60px
        },
        goback() {
            return this.$router.go(-1);
        }
    },
}
</script>