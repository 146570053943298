<template>
  <div class="main dark_mode no-padding">
    <div class="dark scan">
        <div class="hint">
            <h1>請對準掃描 QRCODE</h1>
            加入 Ocard 會員、領取優惠、使用回饋獎勵一掃完成
        </div>
        <div class="container">
          <div class="scan_screen"></div>
        </div>
        <div class="bottom_btns">
            <button class="btn_open" @click="dialog = true">
                <div class="img"><img src="@/assets/images/icon-click-yellow.svg"></div>
                <div class="text">
                    <h1>手動輸入店家代碼</h1>
                    僅支援使用 DaoCoin
                </div>
            </button>
        </div>
        
    </div>
    <FooterMenu :darkMode="isDarkMode" />
    <div class="dark_drawer scan">
        <el-drawer
            title="店家代碼"
            :visible.sync="dialog"
            direction="btt"
            custom-class="demo-drawer"
            ref="drawer"
            size="40%"
            >
            <div class="demo-drawer__content">
                <div class="input">
                    <input type="text" placeholder="請輸入店家代碼">
                    <p>僅支援使用 DaoCoin</p>
                    <button @click="dialog = false">送出</button>
                </div>
            </div>
        </el-drawer> 
    </div>
  </div>
</template>

<script>

import FooterMenu from '@/components/Footer.vue'
export default {
  name: 'memberCard',
  data() {
    return {
        isDarkMode: true,
        dialog: false,
    };
  },
  components: {
    FooterMenu,
  },
  methods: {
    
  }
}
</script>