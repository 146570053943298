<template>
  <div class="main no-padding">
    <div class="dark">
        <div class="top_area">
            <div class="container">
                <div class="title">轉成 DaoCoin 
                    <button @click="goback()" class="btn_close"></button>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="expected">
                    <span class="label">預計轉換</span>
                    <img src="@/assets/images/coin-doucoing.svg">
                    <span class="num">0</span>
                </div>
                <div class="notEnough">以下品牌點數不足，未能轉換</div>
                <div class="list">
                    <ul>
                        <li>
                            <div class="img"><img src="@/assets/images/brand-1.png"></div>
                            <div class="text">橘焱胡同<br>0 點</div>
                            <div class="rule">每2點可換成1 <img src="@/assets/images/coin-doucoing.svg"></div>
                        </li>
                    </ul>
                </div>
                <div class="btns fixed">
                    <button>一鍵全選
                        <span>+0<br>DaoCoin</span>
                    </button>
                </div>
            </div> <!--end of container-->
        </div>
        
    </div>
  </div>
</template>

<script>
export default {
    name: 'exchangeToDao',
    data() {
        return {
            
        };
    },
    methods: {
        goback(){
            return this.$router.go(-1);
        }
    },
}
</script>