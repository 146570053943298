<template>
  <div class="main no-padding">
    <div class="dark">
        <div class="top_area">
            <div class="container">
                <div class="title">回饋獎勵明細
                    <button @click="goback()" class="btn_close"></button>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="inputs">
                    <div class="btns">
                        <button class="btn_submit chooseCoin" @click="dialog = true">{{ coin.value }}
                            <div class="arrow"></div>
                        </button>
                    </div>
                </div>
                <div class="balance">
                    <div class="title">Ocoin 餘額</div>
                    <div class="amount">2 <img src="@/assets/images/coin-doucoing.svg"></div>
                </div>
                <div class="history">
                    <h1>即將到期的 Ocoin</h1>
                    <p>三個月內無即將到期 Ocoin</p>

                    <h1>歷史紀錄</h1>
                    <el-collapse accordion>
                        <el-collapse-item>
                            <template slot="title">
                                <div class="left">
                                    <p>Ocard<br>活動贈點</p>
                                    <p class="word_s">2024/4/617:39<br>使用期限 2025/4/30</p>
                                </div>
                                <div class="right">
                                    +1
                                </div>
                            </template>
                            <div>
                                <span class="title">活動名稱</span>
                                <span class="text">任務-收藏店家</span>
                            </div>
                        </el-collapse-item>
                        <el-collapse-item title="反馈 Feedback">
                            <template slot="title">
                                <div class="left">
                                    <p>Ocard<br>活動贈點</p>
                                    <p class="word_s">2024/4/617:39<br>使用期限 2025/4/30</p>
                                </div>
                                <div class="right">
                                    +1
                                </div>
                            </template>
                            <div>
                                <span class="title">活動名稱</span>
                                <span class="text">任務-收藏店家</span>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div> <!--end of container-->
        </div>
        <el-drawer
            title="請選擇"
            :visible.sync="dialog"
            direction="btt"
            custom-class="demo-drawer"
            ref="drawer"
            size="40%"
            >
            <div class="demo-drawer__content">
                <el-radio-group v-model="selectedCoin">
                    <el-radio class="coin" label="DAOCoin">DAOCoin</el-radio>
                    <el-radio class="coin" label="國泰- 亞洲萬里通">「國泰- 亞洲萬里通」</el-radio>
                    <el-radio class="coin" label="HAPPY GO">HAPPY GO</el-radio>
                    <el-radio class="coin" label="OPEN POINT">OPEN POINT</el-radio>
                </el-radio-group>
            </div>
        </el-drawer> 
    </div>
  </div>
</template>

<script>
export default {
    name: 'feedbackDetail',
    data() {
        return {
            dialog: false,
            selectedCoin: 'DAOCoin',
            coin: {
                value: 'DAOCoin' // 设置一个默认值
            }
        };
    },
    components: {

    },
    mounted() {
        
    },
    methods: {
        goback(){
            return this.$router.go(-1);
        }
    },
    watch: {
        selectedCoin(newValue) {
            // 监听选中的性别值的变化
            this.coin.value = newValue;
            this.dialog = false; // 关闭弹出框
        }
    }
}
</script>