<template>
  <div class="main">
    <div class="home">
      <div class="slider">
        <VueSlickCarousel v-bind="settings">
            <div class="img"><img src ="@/assets/images/idx-slider-1.jpg"></div>
            <div class="img"><img src ="@/assets/images/idx-slider-2.jpg"></div>
            <div class="img"><img src ="@/assets/images/idx-slider-3.jpg"></div>
            <div class="img"><img src ="@/assets/images/idx-slider-4.jpg"></div>
        </VueSlickCarousel>
        <div class="search">
          <router-link to="/search"><input type="text" class="search_bar" placeholder="還在煩惱聚餐要吃啥？來看看附近有什麼吧~"></router-link>
        </div>
      </div> <!--end of slider-->
      <div class="top_card">
        <ul>
          <li><router-link to="/search-coupon">
            <div class="img green"><img src="@/assets/images/icon-card-1.svg"></div>
            <div class="text">嚐鮮享優惠</div></router-link>
          </li>
          <li><router-link to="/mission">
            <div class="img red"><img src="@/assets/images/icon-card-2.svg"></div>
            <div class="text">解任務賺回饋</div></router-link>
          </li>
          <li>
            <div class="img darkGreen"><img src="@/assets/images/icon-card-3.svg"></div>
            <div class="text">外帶美食</div>
          </li>
          <li>
            <div class="img blue"><img src="@/assets/images/icon-card-3.svg"></div>
            <div class="text">外送美食</div>
          </li>
        </ul>
      </div> <!--end of top_card-->
      <div class="recommend list">
        <h1>為你推薦 <button class="btn_more">查看全部</button></h1>
        <VueSlickCarousel v-bind="settings_list">
          
          <div class="card" v-for="(item, index) in recommend_items" :key="index">
            <router-link to="/store">
            <div class="img"><img :src="item.image" alt="carousel-item"></div>
            <div class="shop_info">
              <div class="tag">{{ item.tag }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="info">
                <span class="star">{{ item.star }}</span>
                <span class="location">{{ item.location }}</span>
                <span class="pointback">{{ item.pointback }}</span>
              </div>
            </div>
            </router-link>
          </div>
          
        </VueSlickCarousel>
        <!-- <div class="carousel-container">
          <div class="carousel-wrapper" ref="carouselWrapper">
            <div
              class="carousel-slide"
              v-for="(item, index) in recommend_items"
              :key="index"
              :style="{ transform: `translateX(-${recommendCurrentIndex * 100}%)` }"
              @touchstart="handleTouchStart"
              @touchend="handleTouchEnd('recommend')"
            >
              <div class="card">
                <div class="img"><img :src="item.image" alt="carousel-item"></div>
                <div class="shop_info">
                  <div class="tag">{{ item.tag }}</div>
                  <div class="name">{{ item.name }}</div>
                  <div class="info">
                    <span class="star">{{ item.star }}</span>
                    <span class="location">{{ item.location }}</span>
                    <span class="pointback">{{ item.pointback }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div> <!--end of recommend-->

      <div class="online list">
        <h1>線上外帶外送回饋專區 <button class="btn_more">查看全部</button></h1>
        <VueSlickCarousel v-bind="settings_list">
          <div class="card" v-for="(item, index) in online_items" :key="index">
            <div class="img"><img :src="item.image" alt="carousel-item"></div>
            <div class="shop_info">
              <div class="tag">{{ item.tag }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="info">
                <span class="star">{{ item.star }}</span>
                <span class="location">{{ item.location }}</span>
                <span class="pointback">{{ item.pointback }}</span>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <!-- <div class="carousel-container">
          <div class="carousel-wrapper" ref="carouselWrapper">
            <div
                class="carousel-slide"
                  v-for="(item, index) in online_items"
                  :key="index"
                  :style="{ transform: `translateX(-${onlineCurrentIndex * 100}%)` }"
                  @touchstart="handleTouchStart"
                  @touchend="handleTouchEnd('online')"
            >
              <div class="card">
                <div class="img"><img :src="item.image" alt="carousel-item"></div>
                <div class="shop_info">
                  <div class="tag">{{ item.tag }}</div>
                  <div class="name">{{ item.name }}</div>
                  <div class="info">
                    <span class="star">{{ item.star }}</span>
                    <span class="location">{{ item.location }}</span>
                    <span class="pointback">{{ item.pointback }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div> <!--end of online-->

      <div class="top_card food_type">
        <ul>
          <li>
            <div class="img lightYellow"><img src="@/assets/images/icon-type-1.svg"></div>
            <div class="text">中式美食</div>
          </li>
          <li>
            <div class="img lightBlue"><img src="@/assets/images/icon-type-2.svg"></div>
            <div class="text">鍋物料理</div>
          </li>
          <li>
            <div class="img lightGray"><img src="@/assets/images/icon-type-3.svg"></div>
            <div class="text">日式料理</div>
          </li>
          <li>
            <div class="img lightRed"><img src="@/assets/images/icon-type-4.svg"></div>
            <div class="text">韓式料理</div>
          </li>
          <li>
            <div class="img lightYellow"><img src="@/assets/images/icon-type-5.svg"></div>
            <div class="text">複合式餐飲</div>
          </li>
          <li>
            <div class="img lightBlue"><img src="@/assets/images/icon-type-6.svg"></div>
            <div class="text">咖啡廳</div>
          </li>
          <li>
            <div class="img lightGray"><img src="@/assets/images/icon-type-7.svg"></div>
            <div class="text">港粵料理</div>
          </li>
          <li>
            <div class="img lightRed"><img src="@/assets/images/icon-type-8.svg"></div>
            <div class="text">美式料理</div>
          </li>
          <li>
            <div class="img lightYellow"><img src="@/assets/images/icon-type-9.svg"></div>
            <div class="text">泰式料理</div>
          </li>
          <li>
            <div class="img lightBlue"><img src="@/assets/images/icon-type-10.svg"></div>
            <div class="text">義式料理</div>
          </li>
          <li>
            <div class="img lightGray"><img src="@/assets/images/icon-type-11.svg"></div>
            <div class="text">蔬食料理</div>
          </li>
        </ul>
      </div> <!--end of top_card-->
      
      <div class="popular list_small">
        <h1>熱門店家 <button class="btn_more">查看全部</button></h1>
        <VueSlickCarousel v-bind="settings_list_small">
          <div class="card" v-for="(item, index) in popular_items" :key="index">
            <div class="img"><img :src="item.image" alt="carousel-item"></div>
            <div class="shop_info">
              <div class="tag">{{ item.tag }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="info">
                <span class="star">{{ item.star }}</span>
                <span class="location">{{ item.location }}</span>
                <span class="pointback">{{ item.pointback }}</span>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <!-- <div class="carousel-container">
          <div class="carousel-wrapper" ref="carouselWrapper">
            <div
                class="carousel-slide"
                  v-for="(item, index) in popular_items"
                  :key="index"
                  :style="{ transform: `translateX(-${popularCurrentIndex * 100}%)` }"
                  @touchstart="handleTouchStart"
                  @touchend="handleTouchEnd('popular')"
            >
              <div class="card">
                <div class="img"><img :src="item.image" alt="carousel-item"></div>
                <div class="shop_info">
                  <div class="name">{{ item.name }}</div>
                  <div class="info">
                    <span class="takeout">{{ item.takeout }}</span>
                    <span class="location">{{ item.location }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div> <!--end of popular-->

      <div class="new list_small">
        <h1>新進店家 <button class="btn_more">查看全部</button></h1>
        <VueSlickCarousel v-bind="settings_list_small">
          <div class="card" v-for="(item, index) in new_items" :key="index">
            <div class="img"><img :src="item.image" alt="carousel-item"></div>
            <div class="shop_info">
              <div class="tag">{{ item.tag }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="info">
                <span class="star">{{ item.star }}</span>
                <span class="location">{{ item.location }}</span>
                <span class="pointback">{{ item.pointback }}</span>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <!-- <div class="carousel-container">
          <div class="carousel-wrapper" ref="carouselWrapper">
            <div
                class="carousel-slide"
                  v-for="(item, index) in new_items"
                  :key="index"
                  :style="{ transform: `translateX(-${newCurrentIndex * 100}%)` }"
                  @touchstart="handleTouchStart"
                  @touchend="handleTouchEnd('new')"
            >
              <div class="card">
                <div class="img"><img :src="item.image" alt="carousel-item"></div>
                <div class="shop_info">
                  <div class="name">{{ item.name }}</div>
                  <div class="info">
                    <span class="">{{ item.takeout }}</span>
                    <span class="location">{{ item.location }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div> <!--end of new-->

      <div class="near list_small">
        <h1>在你附近 <button class="btn_more">查看全部</button></h1>
        <VueSlickCarousel v-bind="settings_list_small">
          <div class="card" v-for="(item, index) in near_items" :key="index">
            <div class="img"><img :src="item.image" alt="carousel-item"></div>
            <div class="shop_info">
              <div class="tag">{{ item.tag }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="info">
                <span class="star">{{ item.star }}</span>
                <span class="location">{{ item.location }}</span>
                <span class="pointback">{{ item.pointback }}</span>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <!-- <div class="carousel-container">
          <div class="carousel-wrapper" ref="carouselWrapper">
            <div
                class="carousel-slide"
                  v-for="(item, index) in near_items"
                  :key="index"
                  :style="{ transform: `translateX(-${nearCurrentIndex * 100}%)` }"
                  @touchstart="handleTouchStart"
                  @touchend="handleTouchEnd('near')"
            >
              <div class="card">
                <div class="img"><img :src="item.image" alt="carousel-item"></div>
                <div class="shop_info">
                  <div class="name">{{ item.name }}</div>
                  <div class="info">
                    <span class="">{{ item.takeout }}</span>
                    <span class="location">{{ item.location }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div> <!--end of near-->

      <div class="point list_small">
        <h1>可到店賺取回饋店家 <button class="btn_more">查看全部</button></h1>
        <VueSlickCarousel v-bind="settings_list_small">
          <div class="card" v-for="(item, index) in point_items" :key="index">
            <div class="img"><img :src="item.image" alt="carousel-item"></div>
            <div class="shop_info">
              <div class="tag">{{ item.tag }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="info">
                <span class="star">{{ item.star }}</span>
                <span class="location">{{ item.location }}</span>
                <span class="pointback">{{ item.pointback }}</span>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <!-- <div class="carousel-container">
          <div class="carousel-wrapper" ref="carouselWrapper">
            <div
                class="carousel-slide"
                  v-for="(item, index) in point_items"
                  :key="index"
                  :style="{ transform: `translateX(-${pointCurrentIndex * 100}%)` }"
                  @touchstart="handleTouchStart"
                  @touchend="handleTouchEnd('point')"
            >
              <div class="card">
                <div class="img"><img :src="item.image" alt="carousel-item"></div>
                <div class="shop_info">
                  <div class="name">{{ item.name }}</div>
                  <div class="info">
                    <span class="star">{{ item.star }}</span>
                    <span class="location">{{ item.location }}</span>
                    <span class="pointback">{{ item.pointback }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div> <!--end of point-->

      <div class="member list_small">
        <h1>會員熱烈招募中 <button class="btn_more">查看全部</button></h1>
        <VueSlickCarousel v-bind="settings_list_small">
          <div class="card" v-for="(item, index) in member_items" :key="index">
            <div class="img"><img :src="item.image" alt="carousel-item"></div>
            <div class="shop_info">
              <div class="tag">{{ item.tag }}</div>
              <div class="name">{{ item.name }}</div>
              <div class="info">
                <span class="star">{{ item.star }}</span>
                <span class="location">{{ item.location }}</span>
                <span class="pointback">{{ item.pointback }}</span>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <!-- <div class="carousel-container">
          <div class="carousel-wrapper" ref="carouselWrapper">
            <div
                class="carousel-slide"
                  v-for="(item, index) in member_items"
                  :key="index"
                  :style="{ transform: `translateX(-${memberCurrentIndex * 100}%)` }"
                  @touchstart="handleTouchStart"
                  @touchend="handleTouchEnd('member')"
            >
              <div class="card" :class="item.color">
                <div class="img">
                  <div class="cover"></div>
                  <img :src="item.image" alt="carousel-item">
                </div>
                <div class="shop_info">
                  <div class="name">{{ item.name }}</div>
                  <div class="info">
                    <span class="welcome">加會員拿好禮</span>
                  </div>
                  <div class="button">
                    <button>
                      <img :src="item.logo"> 入會
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div> <!--end of member-->
    </div> <!--end of home-->
    <FooterMenu />
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// @ is an alias to /src
import FooterMenu from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  data() {
    return {
      settings: {
          dots: true,
          focusOnSelect: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          touchThreshold: 1,
          autoplay: true,
      },
      settings_list: {
          focusOnSelect: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          touchThreshold: 2,
      },
      settings_list_small: {
          focusOnSelect: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          touchThreshold: 3,
      },
      recommend_items: [
        { 
          image: require('@/assets/images/idx-slider-1.jpg'),
          tag: '#手作甜點 #微奢甜點季 #日式洋果子',
          name: '海海洋菓',
          star: '4.3 ★',
          location: '4.5km',
          pointback: '到店1.5% 回饋'
        },
        { 
          image: require('@/assets/images/idx-slider-2.jpg'),
          tag: '#手作甜點 #微奢甜點季 #日式洋果子',
          name: '海海洋菓',
          star: '4.3 ★',
          location: '4.5km',
          pointback: '到店1.5% 回饋'
        },
        { 
          image: require('@/assets/images/idx-slider-3.jpg'),
          tag: '#手作甜點 #微奢甜點季 #日式洋果子',
          name: '海海洋菓',
          star: '4.3 ★',
          location: '4.5km',
          pointback: '到店1.5% 回饋'
        },
        { 
          image: require('@/assets/images/idx-slider-4.jpg'),
          tag: '#手作甜點 #微奢甜點季 #日式洋果子',
          name: '海海洋菓',
          star: '4.3 ★',
          location: '4.5km',
          pointback: '到店1.5% 回饋'
        },
        // Add more carousel items as needed
      ],
      online_items: [
        { 
          image: require('@/assets/images/idx-slider-5.jpg'),
          tag: '#手作甜點 #微奢甜點季 #日式洋果子',
          name: '海海洋菓',
          star: '4.3 ★',
          location: '4.5km',
          pointback: '到店1.5% 回饋'
        },
        { 
          image: require('@/assets/images/idx-slider-6.jpg'),
          tag: '#手作甜點 #微奢甜點季 #日式洋果子',
          name: '海海洋菓',
          star: '4.3 ★',
          location: '4.5km',
          pointback: '到店1.5% 回饋'
        },
        { 
          image: require('@/assets/images/idx-slider-7.jpg'),
          tag: '#手作甜點 #微奢甜點季 #日式洋果子',
          name: '海海洋菓',
          star: '4.3 ★',
          location: '4.5km',
          pointback: '到店1.5% 回饋'
        },
        // Add more carousel items as needed
      ],
      popular_items: [
        { 
          image: require('@/assets/images/idx-slider-8.jpg'),
          name: '熱門店家1',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        { 
          image: require('@/assets/images/idx-slider-9.jpg'),
          name: '熱門店家2',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        { 
          image: require('@/assets/images/idx-slider-10.jpg'),
          name: '熱門店家3',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        { 
          image: require('@/assets/images/idx-slider-11.jpg'),
          name: '熱門店家4',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        // Add more carousel items as needed
      ],
      new_items: [
        { 
          image: require('@/assets/images/idx-slider-12.jpg'),
          name: '新進店家1',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        { 
          image: require('@/assets/images/idx-slider-1.jpg'),
          name: '新進店家2',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        { 
          image: require('@/assets/images/idx-slider-2.jpg'),
          name: '新進店家3',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        { 
          image: require('@/assets/images/idx-slider-3.jpg'),
          name: '新進店家4',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        // Add more carousel items as needed
      ],
      near_items: [
        { 
          image: require('@/assets/images/idx-slider-4.jpg'),
          name: '附近店家1',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        { 
          image: require('@/assets/images/idx-slider-5.jpg'),
          name: '附近店家2',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        { 
          image: require('@/assets/images/idx-slider-6.jpg'),
          name: '附近店家3',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        { 
          image: require('@/assets/images/idx-slider-7.jpg'),
          name: '附近店家4',
          takeout: '預約外帶/外送',
          location: '4.5km',
        },
        // Add more carousel items as needed
      ],
      point_items: [
        { 
          image: require('@/assets/images/idx-slider-8.jpg'),
          name: '可回饋店家1',
          star: '4.3 ★',
          location: '4.5km',
          pointback: '到店1.5% 回饋'
        },
        { 
          image: require('@/assets/images/idx-slider-9.jpg'),
          name: '可回饋店家2',
          star: '4.3 ★',
          location: '4.5km',
          pointback: '到店1.5% 回饋'
        },
        { 
          image: require('@/assets/images/idx-slider-10.jpg'),
          name: '可回饋店家3',
          star: '4.3 ★',
          location: '4.5km',
          pointback: '到店1.5% 回饋'
        },
        { 
          image: require('@/assets/images/idx-slider-11.jpg'),
          name: '可回饋店家4',
          star: '4.3 ★',
          location: '4.5km',
          pointback: '到店1.5% 回饋'
        },
        // Add more carousel items as needed
      ],
      member_items: [
        { 
          color: 'brown',
          image: require('@/assets/images/idx-slider-12.jpg'),
          name: '可回饋店家1',
          logo: require('@/assets/images/footer-icon-2.svg'),
        },
        { 
          color: 'tea',
          image: require('@/assets/images/idx-slider-1.jpg'),
          name: '可回饋店家2',
          logo: require('@/assets/images/footer-icon-2.svg'),
        },
        { 
          color: 'brown',
          image: require('@/assets/images/idx-slider-2.jpg'),
          name: '可回饋店家3',
          logo: require('@/assets/images/footer-icon-2.svg'),
        },
        { 
          color: 'tea',
          image: require('@/assets/images/idx-slider-3.jpg'),
          name: '可回饋店家4',
          logo: require('@/assets/images/footer-icon-2.svg'),
        },
        // Add more carousel items as needed
      ],
      // recommendCurrentIndex: 0,
      // onlineCurrentIndex: 0,
      // popularCurrentIndex: 0,
      // newCurrentIndex: 0,
      // nearCurrentIndex: 0,
      // pointCurrentIndex: 0,
      // memberCurrentIndex: 0,
      // touchStartX: 0,
      // touchEndX: 0
    };
  },
  components: {
    FooterMenu,
    VueSlickCarousel
  },
  methods: {
    updateSettingsForScreenWidth() {
      if (window.innerWidth > 1680) {
        this.settings_list.slidesToShow = 4;
        this.settings_list.touchThreshold = 5;
      } else if (window.innerWidth > 1280) {
        this.settings_list.touchThreshold = 3;
        this.settings_list_small.slidesToShow = 4;
        this.settings_list_small.touchThreshold = 5;
      } else if (window.innerWidth > 980) {
        this.settings_list.slidesToShow = 2;
        this.settings_list_small.slidesToShow = 3;
        this.settings_list_small.touchThreshold = 4;
        this.settings_list_small.rows = 1;
        this.settings_list_small.slidesPerRow = 1;
      } else {
        this.settings_list_small.slidesToShow = 2;
        this.settings_list_small.touchThreshold = 3;
        this.settings_list.slidesToShow = 1;
        this.settings_list.touchThreshold = 2;
      }
    }
  },
  mounted() {
    this.updateSettingsForScreenWidth();
    window.addEventListener('resize', this.updateSettingsForScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSettingsForScreenWidth);
  }

}
</script>