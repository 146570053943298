<template>
  <div class="main no-padding">
    <div class="dark">
        <div class="top_area">
            <div class="container">
                <div class="title">雲端發票手機耗碼
                    <button @click="goback()" class="btn_close"></button>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="container">
                <div class="comment center">
                    <h3>綁定電子發票手機條碼</h3>
                    <p>綁定完成後，於指定品牌之會員會員卡條碼頁面，將一併顯示此電子發票手機條碼。方便您出示會員與操作電子發票一頁完成。</p>
                </div>
                <div class="inputs">
                    <input class="tag" placeholder="輸入/開頭之手機條碼">
                    <div class="btns">
                        <button @click="submit()" class="btn_submit">送出</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'eInvoice',
    data() {
        return {
        };
    },
    components: {

    },
    methods: {
        submit(){
            return this.$router.go(-1);
        },
        goback(){
            return this.$router.go(-1);
        }
    }
}
</script>