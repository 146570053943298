<template>
    <div class="article page">
        <div class="top_area top_bar">
            <div class="container">
                <div class="page_title">
                    <button @click="goback()" class="btn_back"></button>
                    餡老滿
                </div>
            </div>
        </div> <!--end of top_area-->
        <div class="content">
            <div class="container">
                <div class="img"><img src="@/assets/images/idx-slider-4.jpg"></div>
                <div class="title">【寵愛媽咪、音樂盛宴】</div>
                <div class="date">2024/4/26</div>
                <div class="text">
                    <p>餡老滿~2024 母親節專案<br><br>
                    5/11（六）~5/12（日）推出特別套餐「老滿金鴨宴」，席間安排古箏現場演奏，在優雅古典氛圍中用餐，獻給媽咪幸福的音樂盛宴念<br><br>
                    、活動門市：南港、吉林、文心<br><br>
                    -<br><br>
                    老滿金鴨宴| 美味獻禮：人氣套餐經典重現<br>
                    台北優惠：於4/30 前預訂金鴨宴且支付訂金，用餐當日贈「預約禮（京味佛跳牆」（小桌送1盅/大桌送2盅，每盅價值590元）<br><br>
                    台中優惠：大桌獨家下殺75折<br><br>
                    -<br><br>
                    箏馨音樂會|琴聲悠揚：古箏獨奏 LIVE 表演<br>
                    演奏時段J午晚各一小<br>
                    快帶媽媽來吃烤鴨聽音樂吧~<br>
                    •點圖片到官網看活動詳情
                    </p>
                    <p>餡老滿~2024 母親節專案<br><br>
                    5/11（六）~5/12（日）推出特別套餐「老滿金鴨宴」，席間安排古箏現場演奏，在優雅古典氛圍中用餐，獻給媽咪幸福的音樂盛宴念<br><br>
                    、活動門市：南港、吉林、文心<br><br>
                    -<br><br>
                    老滿金鴨宴| 美味獻禮：人氣套餐經典重現<br>
                    台北優惠：於4/30 前預訂金鴨宴且支付訂金，用餐當日贈「預約禮（京味佛跳牆」（小桌送1盅/大桌送2盅，每盅價值590元）<br><br>
                    台中優惠：大桌獨家下殺75折<br><br>
                    -<br><br>
                    箏馨音樂會|琴聲悠揚：古箏獨奏 LIVE 表演<br>
                    演奏時段J午晚各一小<br>
                    快帶媽媽來吃烤鴨聽音樂吧~<br>
                    •點圖片到官網看活動詳情
                    </p>
                </div>
            </div> <!--end of container-->
            <div class="bottom_btns">
                <div class="container">
                    <button class="btn_like"></button>
                    <span class="num">2</span>
                </div>
            </div>
        </div> <!--end of content-->
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'Mission',
    data() {
        return {
            input: ''
        };
    },
    components: {
        
    },
    methods: {
        goback(){
            return this.$router.go(-1);
        }
    }
}
</script>