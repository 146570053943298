var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"my_page"},[_c('div',{staticClass:"top_bar"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v("通知中心 "),_c('button',{staticClass:"btn_close",on:{"click":function($event){return _vm.goback()}}})])])]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('button',{staticClass:"notice",on:{"click":function($event){_vm.dialog = true}}},[_vm._m(0),_vm._m(1)]),_c('button',{staticClass:"notice",on:{"click":_vm.gotoCoupon}},[_vm._m(2),_vm._m(3)]),_c('button',{staticClass:"notice",on:{"click":_vm.gotoArticle}},[_vm._m(4),_vm._m(5)])])])]),_c('div',{staticClass:"white_drawer"},[_c('el-drawer',{ref:"drawer",attrs:{"title":"收到新的會員邀請","visible":_vm.dialog,"direction":"btt","custom-class":"demo-drawer","size":"55%"},on:{"update:visible":function($event){_vm.dialog=$event}}},[_c('div',{staticClass:"demo-drawer__content"},[_c('div',{staticClass:"shop_brand"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/idx-slider-6.jpg")}})]),_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/images/member-logo-1.png")}}),_c('br'),_vm._v("小蒙牛餐飲集團")]),_c('div',{staticClass:"cover"},[_c('span',{staticClass:"info"},[_vm._v("小蒙牛餐飲集團集點會員")])])]),_vm._v(" "),_c('div',{staticClass:"hint"},[_vm._v("點按接受，即表示您接受同意該店之隱私權條款")]),_c('div',{staticClass:"btns"},[_c('button',{staticClass:"btn_cancel",on:{"click":function($event){_vm.dialog = false}}},[_vm._v("先不要")]),_c('button',{staticClass:"btn_submit",on:{"click":function($event){_vm.dialog = false}}},[_vm._v("接受")])])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/images/push-logo-2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"name"},[_vm._v("小蒙牛餐飲集團")]),_c('p',[_vm._v("邀請您成為會員")]),_c('div',{staticClass:"date"},[_vm._v("5/13")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/images/push-logo-2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"name"},[_vm._v("小蒙牛餐飲集團")]),_c('p',[_vm._v("您因為「Ocard 嚐鮮券」活動收到了1張奶油明太子香魷義大利麵")]),_c('div',{staticClass:"date"},[_vm._v("5/13")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/images/push-logo-5.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"name"},[_vm._v("餡老滿")]),_c('p',[_vm._v("發給您一則消息「【點10成金 】冷凍餃特價」")]),_c('div',{staticClass:"date"},[_vm._v("5/13")])])
}]

export { render, staticRenderFns }