<template>
    <div class="coupon shop_page">
        <div class="top_area" :style="{ 'background-image': 'url(' + require('@/assets/images/idx-slider-10.jpg') + ')' }">
            <div class="cover"></div>
            <div class="container">
                <button class="btn_close" @click="goback"></button>
                <div class="logo">
                    <img src="@/assets/images/logo.svg"> 店家名稱
                </div>
                <div class="img"><img src="@/assets/images/idx-slider-10.jpg"></div>
                <div class="text">
                    <h1>【頂呱呱嚐鮮券】套餐9折優惠券</h1>
                    <span class="sub">可用效期60天</span>
                </div>
            </div>
        </div> <!--end of top_area-->
        <div class="bottom_area">
            <div class="container">
                <div class="tabs">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane label="嚐鮮券詳情" name="first">
                            <h2>優惠內容</h2>
                            <p>最頂朝台味～<br>憑此券至全台頂呱呱門市<br>購買任一超值套餐可享9折嚐鮮優惠<br>#在地好滋</p>
                            <p>※備註：乙張兌換券限優惠乙份、不可與其他優惠併用</p>
                            <h2>使用方法</h2>
                            <p>1.每券限購買乙套。<br>
                                2. 本券不得與會員卡、抵用券及其他優惠併用。<br>
                                3.僅限於頂呱呱門市現場兌換，不適用外送平台及電話預訂。<br>
                                4. 桃園機場店恕無法使用。<br>
                                5. 僅適用於該活動期間，逾期無效。<br>
                                6. 兌換請於點餐時出示，依服務人員指示操作/ 核銷。<br>
                                7. 本券為贈品，係屬無償取得，不適用商品（服務）禮券記載之規範。<br>
                                8. 使用本券為非發票交易，應以「實際支付的金額」開立發票金額。<br>
                                9.參加活動之消費者視同承認本活動規定之效力。<br>
                                10.如有未盡事宜，頂呱呱保有修改與終止活動內容細節之權利。
                            </p>
                        </el-tab-pane>
                        <el-tab-pane label="適用據點" name="second">
                            <el-collapse v-model="shop" accordion>
                                <el-collapse-item title="頂呱呱 台北瑞光店" name="1">
                                    <div>
                                        <ul class="info">
                                            <li>
                                                <div class="img"><img src="@/assets/images/icon-phone.svg"></div> 02-2345-5678
                                            </li>
                                            <li>
                                                <div class="img"><img src="@/assets/images/icon-map-2.svg"></div> 台北市內湖區瑞光路283號
                                            </li>
                                            <li>
                                                <div class="img"><img src="@/assets/images/icon-clock-black.svg"></div> 營業時間
                                                <p class="time">
                                                    週一 10:30~20:00<br>
                                                    週二 10:30~20:00<br>
                                                    週三 10:30~20:00<br>
                                                    週四 10:30~20:00<br>
                                                    週五 10:30~20:00<br>
                                                    週六 10:30~20:00<br>
                                                    週日 10:30~20:00
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="頂呱呱 松山機場店" name="2">
                                    <div>
                                        <ul class="info">
                                            <li>
                                                <div class="img"><img src="@/assets/images/icon-phone.svg"></div> 02-2345-5678
                                            </li>
                                            <li>
                                                <div class="img"><img src="@/assets/images/icon-map-2.svg"></div> 台北市內湖區瑞光路283號
                                            </li>
                                            <li>
                                                <div class="img"><img src="@/assets/images/icon-clock-black.svg"></div> 營業時間
                                                <p class="time">
                                                    週一 10:30~20:00<br>
                                                    週二 10:30~20:00<br>
                                                    週三 10:30~20:00<br>
                                                    週四 10:30~20:00<br>
                                                    週五 10:30~20:00<br>
                                                    週六 10:30~20:00<br>
                                                    週日 10:30~20:00
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="頂呱呱 台北中崙店" name="3">
                                    <div>
                                        <ul class="info">
                                            <li>
                                                <div class="img"><img src="@/assets/images/icon-phone.svg"></div> 02-2345-5678
                                            </li>
                                            <li>
                                                <div class="img"><img src="@/assets/images/icon-map-2.svg"></div> 台北市內湖區瑞光路283號
                                            </li>
                                            <li>
                                                <div class="img"><img src="@/assets/images/icon-clock-black.svg"></div> 營業時間
                                                <p class="time">
                                                    週一 10:30~20:00<br>
                                                    週二 10:30~20:00<br>
                                                    週三 10:30~20:00<br>
                                                    週四 10:30~20:00<br>
                                                    週五 10:30~20:00<br>
                                                    週六 10:30~20:00<br>
                                                    週日 10:30~20:00
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="頂呱呱 台北昆陽店" name="4">
                                    <div>
                                        <ul class="info">
                                            <li>
                                                <div class="img"><img src="@/assets/images/icon-phone.svg"></div> 02-2345-5678
                                            </li>
                                            <li>
                                                <div class="img"><img src="@/assets/images/icon-map-2.svg"></div> 台北市內湖區瑞光路283號
                                            </li>
                                            <li>
                                                <div class="img"><img src="@/assets/images/icon-clock-black.svg"></div> 營業時間
                                                <p class="time">
                                                    週一 10:30~20:00<br>
                                                    週二 10:30~20:00<br>
                                                    週三 10:30~20:00<br>
                                                    週四 10:30~20:00<br>
                                                    週五 10:30~20:00<br>
                                                    週六 10:30~20:00<br>
                                                    週日 10:30~20:00
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </el-collapse-item>
                                </el-collapse>
                        </el-tab-pane>
                    </el-tabs>
                </div> <!--end of tabs-->
                <div class="bottom_btn">
                    <div class="container">
                        <button @click="submit">領取</button>
                        <span class="limit">限量1,000組 刺餘978組</span>
                    </div>
                </div>
            </div>
        </div> <!--end of bottom_area-->
    </div>
    
</template>

<script>
export default {
    name: 'Coupon',
    data() {
      return {
        activeName: 'first',
        shop: ['1']
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      handleChange(val) {
        console.log(val);
      },
      submit(){
        return this.$router.go(-1);
      },
      goback(){
        return this.$router.go(-1);
      },
    }
}
</script>

<style>

</style>