<template>
    <el-drawer
        title="Dao 線上點"
        :visible.sync="dialog_menu"
        direction="ttb"
        custom-class="menu"
        ref="drawer"
        size="45%"
        >
        <div class="demo-drawer__content">
            <div class="img">
                <div class="circle"><img src="@/assets/images/idx-slider-1.jpg"></div>
                <h2>王小明</h2>
            </div>
            <div class="btns">
                <button @click="goOrder"><i class="el-icon-document"></i>我的訂單</button>
                <button @click="goFAQ"><i class="el-icon-question"></i> 常見問題</button>
            </div>
            <p class="links">隱私權政策 • 使用者條款<br>v5.5.2</p>
            <button class="btn_logout">登出</button>
        </div>
    </el-drawer>
</template>


<script>
export default {
    props: {
        dialog_menu: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        goFAQ() {
            if (this.$route.path !== '/faq') {
                this.$router.push('/faq');
            }
        },
        goOrder() {
            if (this.$route.path !== '/order') {
                this.$router.push('/order');
            }
        }
    }
}
</script>